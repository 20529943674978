import {
  CopyOutlined,
  FilePdfOutlined,
  FileZipOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import { UseMutateFunction } from "react-query";
import { successNotification } from "shared/components/customNotification/Notification";
import { ToolbarButton } from "shared/types/adLibrary";
import {
  BrochureRequest,
  BrochureTriggerResponse,
} from "shared/types/brochureManagement";

export const generateToolbarContents: (
  record?: BrochureRequest,
  isBrochureAdmin?: boolean,
  regenFn?: UseMutateFunction<
    BrochureTriggerResponse,
    Error,
    Record<string, string>,
    unknown
  >,
) => ToolbarButton = (record, isBrochureAdmin, regenFn) => {
  if (!record) return {};

  const encodeBrochureUrl = (originalUrl: string) => {
    const url = new URL(originalUrl);
    const pathnameParts = url.pathname.split("/");

    url.pathname = pathnameParts
      .map(part => encodeURIComponent(decodeURIComponent(part)))
      .join("/");

    return url.href;
  };

  const downloadFile = (url: string, ext: "pdf" | "zip", filename?: string) => {
    const link = document.createElement("a");
    link.download = filename ? `${filename}.${ext}` : url;
    link.href = encodeBrochureUrl(url);
    link.target = "_blank";
    link.click();
  };

  return {
    DownloadPDF: {
      icon: <FilePdfOutlined />,
      onClick: () => {
        if (!record.pdfLink) return;
        downloadFile(record.pdfLink, "pdf", record.filename);
      },
      tooltip: "Download PDF",
      showInContextMenu: true,
      showInInlineMenu: true,
      extraInfo: {
        title: "Download PDF",
      },
      disabled: !record.pdfLink,
    },
    ...(isBrochureAdmin
      ? {
          DownloadZIP: {
            icon: <FileZipOutlined />,
            onClick: () => {
              if (!record.zipLink) return;
              downloadFile(record.zipLink, "zip", record.filename);
            },
            tooltip: "Download ZIP",
            showInContextMenu: true,
            showInInlineMenu: true,
            extraInfo: {
              title: "Download ZIP",
            },
            disabled: !record.zipLink,
          },
          Regenerate: {
            icon: <ReloadOutlined />,
            onClick: async () => {
              if (!record.inputJson) return;
              regenFn?.(JSON.parse(record.inputJson), {
                onSuccess: () => {
                  successNotification({
                    messageLabel:
                      "Brochure regeneration request sent successfully. Please wait for the new files to be generated.",
                  });
                },
              });
            },
            tooltip: "Regenerate",
            showInContextMenu: true,
            showInInlineMenu: true,
            extraInfo: {
              title: "Regenerate",
            },
            disabled: record.status === "processing",
          },
          CustomButton: {
            icon: <CopyOutlined />,
            onClick: () => {
              if (!record.inputJson) return;
              navigator.clipboard.writeText(record.inputJson);
              successNotification({
                messageLabel: "EPS data copied to clipboard",
              });
            },
            tooltip: "Copy EPS data",
            showInContextMenu: true,
            showInInlineMenu: true,
            extraInfo: {
              title: "Copy EPS data",
              icon: <CopyOutlined />,
              tooltipText: "EPS data",
            },
            disabled: !record.inputJson,
            hidden: !isBrochureAdmin,
          },
        }
      : {}),
  };
};
