import { IAd } from "shared/types/adLibrary";
import {
  CampaignPlanner,
  CampaignPlannerCondition,
  CampaignPlannerInstance,
  CampaignPlannerInstanceAd,
  CampaignPlannerInstanceStatus,
  CampaignPlannerList,
  CampaignPlannerStatus,
  CampaignPlannerCondition as Condition,
  CampaignPlannerStatus as Status,
} from "./types";
import { setupEditableCells } from "shared/components/dataList/setupEditableCells";
import { CampaignPlannerColumn } from "./campaignPlannerList/dataList/columns";
import { pickBy } from "lodash";
import { TEMP_ROW_ID } from "./CampaignPlannerContext";
import { CampaignAdStatus } from "shared/types/campaignPlanner";

export const ConditionMap = {
  [Condition.ACTIVE]: {
    color: "green",
    text: "Active",
  },
  [Condition.IN_PROGRESS]: {
    color: "blue",
    text: "In Progress",
  },
  [Condition.EXPIRED]: {
    color: "lightgrey",
    text: "Expired",
  },
} satisfies Record<Condition, { color: string; text: string }>;

export const PlannerStatusMap = {
  [Status.AWAITING_APPROVAL]: {
    color: "yellow",
    text: "Awaiting Approval",
  },
  [Status.DRAFT]: {
    color: "blue",
    text: "Draft",
  },
  [Status.INTERNALLY_APPROVED]: {
    color: "green",
    text: "Internally Approved",
  },
  [Status.DISAPPROVED]: {
    color: "red",
    text: "Disapproved",
  },
  [Status.HAS_REQUESTS]: {
    color: "yellow",
    text: "Has requests",
  },
} satisfies Record<Status, { color: string; text: string }>;

export const InstanceStatusMap = {
  [Status.INTERNALLY_APPROVED]: {
    color: "green",
    text: "Internally Approved",
  },
  [Status.HAS_REQUESTS]: {
    color: "yellow",
    text: "Has requests",
  },
} satisfies Record<
  CampaignPlannerInstanceStatus,
  { color: string; text: string }
>;

export const AdCampaignStatusMap = {
  [CampaignAdStatus.NEVER_LOADED]: {
    key: CampaignAdStatus.NEVER_LOADED,
    value: CampaignAdStatus.NEVER_LOADED,
    color: "blue",
    text: "Pending",
  },
  [CampaignAdStatus.LOADED]: {
    key: CampaignAdStatus.LOADED,
    value: CampaignAdStatus.LOADED,
    color: "green",
    text: "Loaded",
  },
  [CampaignAdStatus.LOADED_EDITED]: {
    key: CampaignAdStatus.LOADED_EDITED,
    value: CampaignAdStatus.LOADED_EDITED,
    color: "green",
    text: "Loaded (Edited)",
  },
  [CampaignAdStatus.PAUSED]: {
    key: CampaignAdStatus.PAUSED,
    value: CampaignAdStatus.PAUSED,
    color: "gray",
    text: "Paused",
  },
  [CampaignAdStatus.LOAD_REQUESTED]: {
    key: CampaignAdStatus.LOAD_REQUESTED,
    value: CampaignAdStatus.LOAD_REQUESTED,
    text: "Load Requested",
    color: "yellow",
  },
  [CampaignAdStatus.PAUSE_REQUESTED]: {
    key: CampaignAdStatus.PAUSE_REQUESTED,
    value: CampaignAdStatus.PAUSE_REQUESTED,
    text: "Pause Requested",
    color: "yellow",
  },
  [CampaignAdStatus.DO_NOT_LOAD]: {
    key: CampaignAdStatus.DO_NOT_LOAD,
    value: CampaignAdStatus.DO_NOT_LOAD,
    text: "Do not load",
    color: "gray",
  },
  [CampaignAdStatus.REACTIVATION_REQUESTED]: {
    key: CampaignAdStatus.REACTIVATION_REQUESTED,
    value: CampaignAdStatus.REACTIVATION_REQUESTED,
    text: "Reactivation Requested",
    color: "yellow",
  },
};

export const AdStatusDropdownStates = {
  NEVER_LOADED: [
    AdCampaignStatusMap.NEVER_LOADED,
    AdCampaignStatusMap.LOAD_REQUESTED,
    AdCampaignStatusMap.DO_NOT_LOAD,
  ],
  LOAD_REQUESTED: [
    AdCampaignStatusMap.LOAD_REQUESTED,
    AdCampaignStatusMap.NEVER_LOADED,
    AdCampaignStatusMap.DO_NOT_LOAD,
  ],
  DO_NOT_LOAD: [
    AdCampaignStatusMap.DO_NOT_LOAD,
    AdCampaignStatusMap.NEVER_LOADED,
    AdCampaignStatusMap.LOAD_REQUESTED,
  ],
  LOADED: [AdCampaignStatusMap.LOADED, AdCampaignStatusMap.PAUSE_REQUESTED],
  PAUSE_REQUESTED: [
    AdCampaignStatusMap.PAUSE_REQUESTED,
    AdCampaignStatusMap.PAUSED,
    AdCampaignStatusMap.LOADED,
  ],
  PAUSED: [
    AdCampaignStatusMap.PAUSED,
    AdCampaignStatusMap.REACTIVATION_REQUESTED,
  ],
  REACTIVATION_REQUESTED: [
    AdCampaignStatusMap.REACTIVATION_REQUESTED,
    AdCampaignStatusMap.LOADED,
    AdCampaignStatusMap.PAUSED,
  ],
  LOADED_EDITED: [
    AdCampaignStatusMap.LOADED_EDITED,
    AdCampaignStatusMap.LOADED,
    AdCampaignStatusMap.PAUSE_REQUESTED,
    AdCampaignStatusMap.LOAD_REQUESTED,
  ],
};

export const getAdCampaignStatusColor = (status: CampaignAdStatus) => {
  if (!AdCampaignStatusMap[status]) {
    throw new Error(`Invalid status: ${status}`);
  }

  return AdCampaignStatusMap[status].color;
};

export const getPlannerIds = (data: CampaignPlannerList) => {
  return data.map(planner => planner.id);
};

export const getEditableColumns = <
  T extends CampaignPlanner | CampaignPlannerInstance,
>(
  columns: CampaignPlannerColumn<T>[],
  onEditEnd: (item: T) => void,
) => {
  return setupEditableCells<T>({
    columns,
    handleSave: onEditEnd,
  });
};

export const getOrphanedInstances = (
  selectedPlanners: string[],
  selectedInstances: Record<string, string[]>,
) => {
  return pickBy(selectedInstances, (value, key) => {
    return !selectedPlanners.includes(key);
  });
};

export const buildDeletePlannerInstanceMessage = (
  plannerCount: number,
  instanceCount: number,
) => {
  return plannerCount > 0
    ? buildDeletePlannerMessage(plannerCount, instanceCount)
    : buildDeleteInstanceMessage(instanceCount);
};

const buildDeletePlannerMessage = (
  plannerCount: number,
  instanceCount: number,
) => {
  const dynamicText1 = plannerCount === 1 ? "this" : `${plannerCount}`;
  const dynamicText2 =
    plannerCount === 1 ? "Campaign Planner" : "Campaign Planners";
  const dynamicText3 = plannerCount === 1 ? "it" : "them";
  const dynamicText4 = instanceCount === 0 ? "" : " and all its instances";

  return {
    title: `Delete ${dynamicText2}`,
    content: `Are you sure you want to delete ${dynamicText1} ${dynamicText2}${dynamicText4}? This action cannot be reversed. Any ads within ${dynamicText3} will remain in Ad Library.`,
  };
};

const buildDeleteInstanceMessage = (instanceCount: number) => {
  const dynamicText1 = instanceCount === 1 ? "this" : `${instanceCount}`;
  const dynamicText2 =
    instanceCount === 1 ? "Planner Instance" : "Planner Instances";
  const dynamicText3 = instanceCount === 1 ? "it" : "them";

  return {
    title: `Delete ${dynamicText2}`,
    content: `Are you sure you want to delete ${dynamicText1} ${dynamicText2}? This action cannot be reversed. Any ads within ${dynamicText3} will remain in Ad Library.`,
  };
};

export const isNewItem = (
  item: Partial<CampaignPlanner | CampaignPlannerInstance>,
) => {
  return item.id === TEMP_ROW_ID;
};

export const createPlannerObject = (
  planner?: Partial<CampaignPlanner>,
): CampaignPlanner => ({
  name: "",
  instances: [],
  condition: CampaignPlannerCondition.IN_PROGRESS,
  status: CampaignPlannerStatus.DRAFT,
  id: TEMP_ROW_ID,
  adShells: [],
  updatedAt: new Date().toISOString(),
  updatedBy: "",
  createdAt: new Date().toISOString(),
  createdBy: "",
  ...planner,
});

export const createPlannerInstanceObject = (
  instance?: Partial<CampaignPlannerInstance>,
): CampaignPlannerInstance => ({
  name: "",
  id: TEMP_ROW_ID,
  adShells: [],
  adStatuses: {},
  updatedAt: new Date().toISOString(),
  updatedBy: "",
  createdAt: new Date().toISOString(),
  createdBy: "",
  status: CampaignPlannerInstanceStatus.INTERNALLY_APPROVED,
  condition: CampaignPlannerCondition.ACTIVE,
  ...instance,
});

export const toCampaignPlannerAd: (
  ad: IAd,
  source: string,
) => CampaignPlannerInstanceAd = (ad, source) => ({
  ...ad,
  source,
  campaignStatus: CampaignAdStatus.NEVER_LOADED,
});

export const areAllAdsReadyToLoad = (ads: IAd[] = []): boolean => {
  return (
    !!ads.length &&
    ads.every(
      ad =>
        ad.campaignStatus &&
        [
          CampaignAdStatus.LOAD_REQUESTED,
          CampaignAdStatus.LOADED,
          CampaignAdStatus.PAUSE_REQUESTED,
          CampaignAdStatus.REACTIVATION_REQUESTED,
          CampaignAdStatus.LOADED_EDITED,
        ].includes(ad.campaignStatus),
    )
  );
};
