import { Select } from "antd";
import { Rule } from "antd/lib/form";
import { useEffect, useReducer } from "react";
import { useMaterialFormInstance } from "screens/adLibrary/marketingMaterials/MarketingMaterialsProvider";
import { AccountDrawer } from "shared/components/salesEnablement/accounts/AccountDrawer";
import FormColumn from "shared/components/salesEnablement/accounts/accountDrawer/FormColumn";
import CreateNewSelectLabel from "shared/components/select/CreateNewSelectLabel";
import EditableSelectLabel from "shared/components/select/EditableSelectLabel";
import { useFetchAccounts } from "shared/hooks/salesEnablement/useFetchAccounts";
import { AccountField } from "shared/types/marketingMaterials";
import { Account } from "shared/types/salesEnablement";

type Props = Readonly<{
  value?: string | AccountField;
  onChange?: (value: string | AccountField | undefined) => void;
  disabled?: boolean;
}>;

type AccountDrawerAction =
  | {
      type: "EDIT_ACCOUNT";
      payload: Account;
    }
  | {
      type: "CREATE_ACCOUNT" | "NONE";
    };

type AccountDrawerInputState =
  | {
      isDrawerOpen: false;
      drawerState: "NONE";
      selectedAccount: undefined;
    }
  | {
      isDrawerOpen: true;
      drawerState: "EDIT_ACCOUNT";
      selectedAccount: Account;
    }
  | {
      isDrawerOpen: true;
      drawerState: "CREATE_ACCOUNT";
      selectedAccount: undefined;
    };

const useAccountDrawerState = () => {
  const [accountDrawerState, dispatchAccountDrawerState] = useReducer(
    (
      state: AccountDrawerInputState,
      action: AccountDrawerAction,
    ): AccountDrawerInputState => {
      switch (action.type) {
        case "EDIT_ACCOUNT":
          return {
            isDrawerOpen: true,
            drawerState: "EDIT_ACCOUNT",
            selectedAccount: action.payload,
          };
        case "CREATE_ACCOUNT":
          return {
            isDrawerOpen: true,
            drawerState: "CREATE_ACCOUNT",
            selectedAccount: undefined,
          };
        case "NONE":
          return {
            isDrawerOpen: false,
            drawerState: "NONE",
            selectedAccount: undefined,
          };
        default:
          return state;
      }
    },
    {
      isDrawerOpen: false,
      drawerState: "NONE",
      selectedAccount: undefined,
    },
  );

  return {
    accountDrawerState,
    dispatchAccountDrawerState,
  };
};

const EditableAccountLayerInput = ({ value, onChange, disabled }: Props) => {
  if (!onChange) throw new Error("onChange is required");

  useEffect(() => {
    if (typeof value === "string") {
      onChange({ value: value, type: "account" });
    }
  }, [value, onChange]);

  const { data: accounts = [], isLoading } = useFetchAccounts();
  const { accountDrawerState, dispatchAccountDrawerState } =
    useAccountDrawerState();

  const createNewOption = {
    value: "",
    label: (
      <CreateNewSelectLabel
        onCreateClick={() =>
          dispatchAccountDrawerState({ type: "CREATE_ACCOUNT" })
        }
        label="Add Account"
      />
    ),
    labelEdit: "",
  };

  const options = [
    createNewOption,
    ...accounts.map(account => ({
      value: account.id,
      label: account.name,
      labelEdit: (
        <EditableSelectLabel
          onEditClick={() =>
            dispatchAccountDrawerState({
              type: "EDIT_ACCOUNT",
              payload: account,
            })
          }
          label={account.name}
        />
      ),
    })),
  ];

  const onAccountChange = (value: string) => {
    const account = accounts.find(acc => acc.id === value);
    onChange?.({ value, type: "account", name: account?.name });
  };

  return (
    <>
      <Select
        loading={isLoading}
        placeholder="Please Select"
        options={options}
        value={typeof value === "string" ? value : value?.value}
        onChange={onAccountChange}
        optionLabelProp="labelEdit"
        disabled={disabled}
        showSearch
        optionFilterProp="label"
      />
      {accountDrawerState.drawerState === "EDIT_ACCOUNT" && (
        <AccountDrawer
          isDrawerOpen
          canDelete={false}
          drawerState={accountDrawerState.drawerState}
          employees={accountDrawerState.selectedAccount.employees}
          selectedAccount={accountDrawerState.selectedAccount}
          disabled={disabled}
          onSaveSuccess={() =>
            dispatchAccountDrawerState({
              type: "NONE",
            })
          }
          onClose={() =>
            dispatchAccountDrawerState({
              type: "NONE",
            })
          }
          formChildren={
            <FormColumn disabled={disabled} style={{ width: "100%" }} />
          }
          drawerWidth="500px"
        />
      )}
      {accountDrawerState.drawerState === "CREATE_ACCOUNT" && (
        <AccountDrawer
          isDrawerOpen
          canDelete={false}
          drawerState={accountDrawerState.drawerState}
          employees={[]}
          selectedAccount={undefined}
          disabled={disabled}
          onSaveSuccess={(newAccount: Account) => {
            onChange?.({
              value: newAccount.id,
              type: "account",
              name: newAccount?.name,
            });
            dispatchAccountDrawerState({
              type: "NONE",
            });
          }}
          onClose={() => {
            onChange(undefined);
            dispatchAccountDrawerState({
              type: "NONE",
            });
          }}
          formChildren={
            <FormColumn disabled={disabled} style={{ width: "100%" }} />
          }
          drawerWidth="500px"
        />
      )}
    </>
  );
};

const ArchiveAccountLayerInput = ({ value, disabled }: Props) => {
  const option =
    typeof value === "string"
      ? {
          label: value,
          value: value,
        }
      : {
          label: value?.name ?? "",
          value: value?.value ?? "",
        };
  return (
    <Select
      placeholder="Please Select"
      options={[option]}
      value={typeof value === "string" ? value : value?.value}
      disabled={disabled}
    />
  );
};

export const AccountLayerInput = ({ value, onChange, disabled }: Props) => {
  const form = useMaterialFormInstance();
  const isArchiveForm = form.getFieldValue("isArchiveForm");

  return isArchiveForm ? (
    <ArchiveAccountLayerInput value={value} disabled={disabled} />
  ) : (
    <EditableAccountLayerInput
      value={value}
      onChange={onChange}
      disabled={disabled}
    />
  );
};

export const validateRequired: Rule[] = [
  {
    validator: async (_, value?: AccountField) => {
      if (!value) throw new Error("Account is required");

      if (!value.name || !value.value) throw new Error("Account is required");

      return Promise.resolve();
    },
    type: "object",
    required: true,
    message: "This field is required.",
  },
];

export default AccountLayerInput;
