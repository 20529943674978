import { useState } from "react";
import styles from "./ExportDrawer.module.scss";
import { useFeedContext } from "../shared/contexts/FeedContext";
import { CallToAction, FeedTblCol } from "shared/types/assetExporter";
import {
  DataListProvider,
  IDataListColumns,
  createDataListConfig,
} from "shared/components/dataList";
import { fetchFeedTblRow } from "shared/hooks/assetExporter/useFeedConfigFetch";
import { FillAdInfoTable } from "./FillAdInfoTable";
import { errorNotification } from "shared/components/customNotification/Notification";
import { CellProvider } from "screens/assetExporter/feedConfiguration/editableCell/CellContext";
import { Alert } from "antd";
import { useAdLibraryExportContext } from "./AdLibraryExportContext";
import { hasInvalidCta } from "./utils/callToActionValuesValidations";

export const FillAdInfo = () => {
  const { columns: feedColumns, feedId } = useFeedContext();
  const { ctaValidity } = useAdLibraryExportContext();
  const [page, setPage] = useState(1);

  const columns: IDataListColumns<FeedTblCol> = Object.assign(
    {},
    ...feedColumns.map(feedColumn => ({
      [feedColumn.key]: {
        //todo: add sort fn
      },
    })),
  );

  const dataListConfig = createDataListConfig(columns, {
    queryKey: [feedId, page],
    enabled: !!feedId,
    queryFn: async () => {
      try {
        const { rows } = await fetchFeedTblRow({
          feedId: feedId ?? "",
          page: page.toString(),
        });
        return rows;
      } catch (err) {
        if (err instanceof Error)
          errorNotification({
            messageLabel: `Failed to fetch feed data: ${err.message}`,
          });
        errorNotification({
          messageLabel: "Failed to fetch feed data: An unknown error occurred.",
        });
      }
    },
    cacheTime: 0,
  });

  return (
    <div className={styles.fillAdInfoWrapper}>
      <div>
        Fill in the fields of each ad as you see fit before exporting to the Ad
        Library.
      </div>

      <DataListProvider config={dataListConfig}>
        <CellProvider>
          {hasInvalidCta(ctaValidity) && (
            <Alert
              message={`There are one or more invalid CTA Button values. Please ensure you enter a valid value to proceed: 
             ${Object.values(CallToAction).join(", ")}.`}
              type="error"
              showIcon
            />
          )}
          <FillAdInfoTable page={page} setPage={setPage} />
        </CellProvider>
      </DataListProvider>
    </div>
  );
};
