import { useMemo } from "react";
import { produce } from "immer";
import { TableProps } from "antd";
import { getFillAdInfoTableData } from "./utils/getFillAdInfoTableData";
import {
  AdInfoUpdates,
  FillAdInfoTableData,
  RecommendedColumnTitle,
} from "./types";
import { getColumns } from "./FillAdInfoColumns";
import { useFeedContext } from "../shared/contexts/FeedContext";
import { StringParam, useQueryParams } from "use-query-params";
import { useDataList } from "shared/components/dataList";
import { FeedTblRow } from "shared/types/assetExporter";
import { successNotification } from "shared/components/customNotification/Notification";
import { useAdLibraryExportContext } from "./AdLibraryExportContext";
import { useCellContext } from "screens/assetExporter/feedConfiguration/editableCell/CellContext";
import {
  isCallToActionValue,
  validateCTAButton,
} from "./utils/callToActionValuesValidations";

type UseFillAdInfoTable = (page: number) => {
  columns: TableProps<FillAdInfoTableData>["columns"];
  data: FillAdInfoTableData[];
  isLoading: boolean;
};

export const useFillAdInfoTable: UseFillAdInfoTable = page => {
  const [dataList] = useDataList<FeedTblRow>();
  const { assetBatchesForSelectedRows, selectedRows, feedId } =
    useFeedContext();
  const {
    columnMatches,
    adType,
    adInfoUpdates,
    setAdInfoUpdates,
    setCtaValidity,
    ctaValidity,
  } = useAdLibraryExportContext();
  const { editingField, editingRow } = useCellContext();

  const [queryParams] = useQueryParams({ feedName: StringParam });
  const { feedName } = queryParams;

  const selectedFeedRows = useMemo(
    () =>
      dataList.data.filter(feedRow =>
        selectedRows.includes(feedRow.rowIdentifier),
      ),
    [dataList, selectedRows],
  );

  const tableData = useMemo(() => {
    if (!feedName || !adType) return [];
    const data: FillAdInfoTableData[] = getFillAdInfoTableData({
      assetBatchesForSelectedRows,
      data: selectedFeedRows,
      feedName,
      columnMatches,
      adType,
      page,
      feedId,
      adInfoUpdates,
    });

    validateCTAButton(data, ctaValidity, setCtaValidity);

    return data;
  }, [
    feedName,
    adType,
    assetBatchesForSelectedRows,
    selectedFeedRows,
    columnMatches,
    page,
    feedId,
    adInfoUpdates,
    ctaValidity,
    setCtaValidity,
  ]);

  const handleSave = (value: string) => {
    const rowIdentifier = selectedFeedRows[+editingRow].rowIdentifier;
    const previousValues = { ...adInfoUpdates };

    setAdInfoUpdates(
      produce(previousAdInfoUpdates => {
        previousAdInfoUpdates[rowIdentifier] = {
          ...previousAdInfoUpdates[rowIdentifier],
          [editingField]: value,
        };
        return previousAdInfoUpdates;
      }),
    );

    const isValidCTA =
      editingField === RecommendedColumnTitle.CTAButton &&
      isCallToActionValue(value);

    if (
      editingField === RecommendedColumnTitle.CTAButton &&
      isValidCTA !== ctaValidity[editingRow]
    ) {
      setCtaValidity(prevCtaValidity => ({
        ...prevCtaValidity,
        [editingRow]: isValidCTA,
      }));
    }

    successNotification({
      messageLabel: "Text has been saved.",
      size: "big",
      linkText: "Undo",
      duration: 10,
      onClickLink: () => setAdInfoUpdates(previousValues),
    });
  };

  const handleApplyToAll = (value: string) => {
    const previousValues = { ...adInfoUpdates };

    setAdInfoUpdates(previousAdInfoUpdates =>
      selectedRows.reduce<AdInfoUpdates>((acc, rowIdentifier) => {
        acc[rowIdentifier] = {
          ...previousAdInfoUpdates[rowIdentifier],
          [editingField]: value,
        };
        return acc;
      }, {}),
    );

    successNotification({
      messageLabel: "The changes have been applied to all rows and saved.",
      size: "big",
      linkText: "Undo",
      duration: 10,
      onClickLink: () => setAdInfoUpdates(previousValues),
    });
  };

  const columns = getColumns(adType, handleSave, handleApplyToAll);

  return {
    columns,
    data: tableData,
    isLoading: dataList.isLoading,
  };
};
