import {
  GetFillAdInfoTableData,
  RecommendedColumnTitle,
  FillAdInfoColumnTitle,
  RecommendedColumnMatch,
} from "../types";
import { fillAdInfoColumns, recommendedToAdInfoColumnMap } from "../constants";
import { getNameForRow } from "../../shared/utils";
import { FeedTblRow } from "shared/types/assetExporter";
import { getThumbnailURL } from "./getThumbnailURL";

/**
 * Generate the ad info table data by mapping feed data to rows using the column matches or the user-defined updates to the data.
 * If ad name is matched to an "asset name" row, automatically generate the Ad Name based on the
 * naming rule pattern for the matched asset batch. Also generates thumbnail URLs if story/feed
 * media columns have been matched.
 */
export const getFillAdInfoTableData: GetFillAdInfoTableData = ({
  data,
  feedName,
  feedId,
  columnMatches,
  adType,
  page,
  adInfoUpdates,
}) =>
  data.map((rowData, rowDataIdx) => {
    // total index for all data, including previous pages' data
    const totalIndex = (page - 1) * 50 + rowDataIdx;

    // initialize an object to hold the ad information for the current row
    const fillAdInfoRowData = fillAdInfoColumns[adType].reduce<{
      [key in FillAdInfoColumnTitle]?: string | null;
    }>((acc, column) => ({ ...acc, [column]: null }), {});

    const addThumbnailURL = (columnMatch: RecommendedColumnMatch) => {
      const { recommendedColumnTitle, assetBatchCreatedAt, assetBatchId } =
        columnMatch;
      if (!assetBatchCreatedAt || !recommendedColumnTitle) return;

      fillAdInfoRowData[recommendedToAdInfoColumnMap[recommendedColumnTitle]] =
        getThumbnailURL(
          feedId,
          assetBatchId,
          assetBatchCreatedAt,
          rowData.rowIdentifier,
        );
    };

    // populate the ad info row data based on column matches
    Object.entries(columnMatches).forEach(([key, value]) => {
      const recommendedColumnTitle = value.recommendedColumnTitle;

      if (
        recommendedColumnTitle === RecommendedColumnTitle.StoryMedia ||
        recommendedColumnTitle === RecommendedColumnTitle.InFeedMedia
      ) {
        addThumbnailURL(value);
      } else if (
        recommendedColumnTitle === RecommendedColumnTitle.AdName &&
        value.namingRulePattern &&
        value.assetBatchName
      ) {
        // generate the ad name based on the if the Ad Name column was matched to an asset batch naming rule pattern
        fillAdInfoRowData[FillAdInfoColumnTitle.AdName] = getNameForRow(
          rowData,
          value.namingRulePattern,
          feedName,
          value.assetBatchName,
          totalIndex,
        );
      } else if (recommendedColumnTitle === RecommendedColumnTitle.CTAButton) {
        fillAdInfoRowData[recommendedColumnTitle] =
          rowData[key as keyof FeedTblRow]
            ?.toString()
            .replace(/_/g, " ")
            .replace(/\b\w/g, char => char.toUpperCase()) ?? null;
      } else if (recommendedColumnTitle !== null) {
        fillAdInfoRowData[recommendedColumnTitle] =
          rowData[key as keyof FeedTblRow]?.toString() ?? null;
      }
    });

    // overwrite any fields which have been updated with their updated value
    return {
      ...fillAdInfoRowData,
      ...adInfoUpdates[rowData.rowIdentifier],
    };
  });
