import { Checkbox, Collapse } from "antd";
import { CheckboxValueType } from "antd/lib/checkbox/Group";
import { fabric } from "fabric";
import { orderBy } from "lodash";
import { useCallback, useEffect } from "react";
import { ImageVariableInput } from "screens/assetExporter/feedConfigurationV2/assetBatchDrawer/panelVariables/ImageVariableInput";
import { LogoVariableInput } from "screens/assetExporter/feedConfigurationV2/assetBatchDrawer/panelVariables/LogoVariableInput";
import { TextVariableInput } from "screens/assetExporter/feedConfigurationV2/assetBatchDrawer/panelVariables/TextVariableInput";
import { getValueMappingKey } from "screens/assetExporter/feedConfigurationV2/assetBatchDrawer/utils";
import { useAssetBatchesContext } from "screens/assetExporter/feedConfigurationV2/shared/contexts/AssetBatchesContext";
import { getVarsFromCanvas } from "screens/assetExporter/feedConfigurationV2/shared/contexts/AssetBatchesContext.utils";
import { AssetBatchesTemplateCompositionProvider } from "screens/assetExporter/feedConfigurationV2/shared/contexts/AssetBatchesTemplateCompositionContext";
import { useAssetBatchesValueMappingContext } from "screens/assetExporter/feedConfigurationV2/shared/contexts/AssetBatchesValueMappingContext";
import { useVideoStitchingContext } from "screens/assetExporter/feedConfigurationV2/shared/contexts/VideoStitchingContext";
import {
  TCanvasJSON,
  TVariable,
  mediaTypes,
} from "screens/assetExporter/feedConfigurationV2/shared/types";
import { fetchCanvasJson } from "shared/components/contextAPI/shared/RenderTemplate";
import { TComposition, TTemplateComposition } from "shared/types/assetExporter";
import styles from "../PanelVariables.module.scss";

interface CompositionProps {
  composition: TTemplateComposition;
  activeKey: string[];
  sortedVariables: TVariable[];
  isActive: boolean;
  setSortedVariables: React.Dispatch<React.SetStateAction<TVariable[]>>;
  handleOnChange: (compositionId: string) => void;
  customHeader: (composition: TComposition, isActive: boolean) => JSX.Element;
}

export const TemplateComposition = ({
  composition,
  activeKey,
  isActive,
  setSortedVariables,
  handleOnChange,
  customHeader,
}: CompositionProps) => {
  const {
    variables: allVariables,
    templatesToUse,
    setCompositions,
    backgroundMedias,
  } = useAssetBatchesContext();
  const { updateValueMappings } = useAssetBatchesValueMappingContext();
  const { videoAudios, setVideoAudios, setBackgroundAudios } =
    useVideoStitchingContext();

  const updateVariablesFromCanvas = useCallback(async () => {
    const { canvasJsonUrl } =
      templatesToUse.find(template => template.id === composition.template) ||
      {};

    if (!canvasJsonUrl) return;

    const json = (await fetchCanvasJson(canvasJsonUrl)) as TCanvasJSON;
    const canvas = new fabric.Canvas(document.createElement("canvas"));

    canvas.loadFromJSON(json, () => {
      const variables = getVarsFromCanvas(canvas);
      const sameCompositionIdVariables = allVariables
        .filter(allVar => allVar.compositionId === composition.compositionId)
        .map(allVar => allVar.variables)
        .flat();

      const isSomeVariableNotIncluded = variables.some(
        variable =>
          !sameCompositionIdVariables.some(
            sameVar => sameVar.id === variable.id,
          ),
      );
      const isLengthSame =
        variables.length === sameCompositionIdVariables.length;

      if (isSomeVariableNotIncluded || !isLengthSame) {
        setSortedVariables(variables);
        updateValueMappings(variables, composition);
      }
    });
  }, [
    allVariables,
    composition,
    setSortedVariables,
    templatesToUse,
    updateValueMappings,
  ]);

  useEffect(() => {
    updateVariablesFromCanvas();
  }, [updateVariablesFromCanvas]);

  const getSortedVariables = useCallback(
    (composition: TComposition) => {
      const variables = allVariables.find(
        vars => vars.compositionId === composition.compositionId,
      )?.variables;
      if (!variables) return [];
      return [
        ...variables.filter(
          variable => variable.variable === "Theme Background",
        ),
        ...orderBy(
          variables,
          variable =>
            `${variable.type}-${variable.variable}-${variable.logoData?.logoType}`,
          ["asc"],
        ).filter(
          variable =>
            mediaTypes.includes(variable.type) &&
            variable.variable !== "Theme Background",
        ),
      ];
    },
    [allVariables],
  );

  const getSortedTextVariables = useCallback(
    (composition: TComposition) => {
      const variables = allVariables.find(
        vars => vars.compositionId === composition.compositionId,
      )?.variables;
      return orderBy(variables, variable => variable.variable, ["asc"]).filter(
        variable =>
          ["text", "cssStyle", "cssAttribute", "linkHref", "linkText"].includes(
            variable.type,
          ),
      );
    },
    [allVariables],
  );

  const onChange = (values: CheckboxValueType[], compositionId: string) => {
    // Find if any of the values is a theme_background variable
    const themeBackgroundVariable = allVariables
      .flatMap(item => item.variables)
      .find(
        variable =>
          variable.variable === "Theme Background" &&
          values.includes(variable.id),
      );

    setVideoAudios(prev => {
      const index = prev.findIndex(
        item => item.compositionId === compositionId,
      );

      if (index !== -1) {
        // If it exists, update the variableId
        return prev.map((item, i) =>
          i === index ? { ...item, variableIds: values as string[] } : item,
        );
      } else {
        // If it doesn't exist, add a new item to the array
        return prev.concat({
          compositionId,
          variableIds: values as string[],
          canvas: undefined,
        });
      }
    });

    // Update backgroundAudios
    setBackgroundAudios(prev => {
      if (themeBackgroundVariable) {
        const audioEle = document.createElement("audio");
        audioEle.src = backgroundMedias?.[compositionId]?.src || "";
        audioEle.id = themeBackgroundVariable.id ?? "";
        return {
          ...prev,
          [compositionId]: audioEle,
        };
      } else {
        const { [compositionId]: _, ...rest } = prev;
        return rest;
      }
    });

    setCompositions(prev => {
      return prev.map(composition => {
        if (composition.compositionId === compositionId) {
          return { ...composition, audioSelVariableId: values as string[] };
        }
        return composition;
      });
    });
  };
  return (
    <AssetBatchesTemplateCompositionProvider
      key={composition.compositionId}
      editingComposition={composition}
    >
      <Collapse
        activeKey={activeKey}
        onChange={() => handleOnChange(composition.compositionId)}
        bordered={false}
        className={styles.collapseContainer}
      >
        <Collapse.Panel
          key={composition.compositionId}
          header={customHeader(composition, isActive)}
          showArrow={false}
        >
          {getSortedVariables(composition).map(variable =>
            variable.type === "logo" ? (
              <LogoVariableInput
                key={`${composition.compositionId}-${variable.id}-${variable.variable}`}
                variable={variable}
                valueMapping={
                  composition.variables[getValueMappingKey(variable)]
                }
                mappingKey={getValueMappingKey(variable)}
              />
            ) : (
              <Checkbox.Group
                key={`${composition.compositionId}-${variable.id}-${variable.variable}`}
                className={styles.radioGroup}
                onChange={e => onChange(e, composition.compositionId)}
                value={
                  videoAudios.find(
                    item => item.compositionId === composition.compositionId,
                  )?.variableIds || []
                }
              >
                <ImageVariableInput
                  compositionId={composition.compositionId}
                  variable={variable}
                  valueMapping={
                    composition.variables[getValueMappingKey(variable)]
                  }
                  mappingKey={getValueMappingKey(variable)}
                />
              </Checkbox.Group>
            ),
          )}

          {getSortedTextVariables(composition).map((variable, idx) => {
            const mappingKey = getValueMappingKey(variable);

            return (
              <TextVariableInput
                key={`${composition.compositionId}-${variable.id}-${variable.variable}-${idx}`}
                variable={variable}
                valueMapping={composition.variables[mappingKey]}
                mappingKey={mappingKey}
              />
            );
          })}
        </Collapse.Panel>
      </Collapse>
    </AssetBatchesTemplateCompositionProvider>
  );
};
