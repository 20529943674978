import { useState } from "react";
import {
  CopyOutlined,
  EditOutlined,
  EyeOutlined,
  InteractionOutlined,
} from "@ant-design/icons";
import { Image } from "antd";
import { successNotification } from "shared/components/customNotification/Notification";
import styles from "./EditCell.module.scss";
import { MediaPreviewModal } from "shared/components/MediaPreviewModal";
import { ActionIcon } from "./ActionIcon";

interface ActionIconsProps {
  value: string;
  toggleEditing: () => void;
  onApplyToAll?: () => void;
  imageURL?: string | null;
}

export const ActionIcons = ({
  value,
  onApplyToAll,
  imageURL,
  toggleEditing,
}: ActionIconsProps) => {
  const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);

  const handlePreviewImage = () => setIsPreviewModalOpen(true);

  const handleCancelImagePreview = () => setIsPreviewModalOpen(false);

  const handleCopy = () => {
    navigator?.clipboard?.writeText(value);
    successNotification({
      messageLabel: "Copied to clipboard",
      size: "big",
    });
  };

  const handleEdit = () => toggleEditing();

  const handleApplyToAll = () => onApplyToAll && onApplyToAll();

  return (
    <>
      <MediaPreviewModal
        visible={isPreviewModalOpen}
        onCancel={handleCancelImagePreview}
      >
        <Image
          preview={false}
          width="100%"
          height="100%"
          src={value.toString()}
        />
      </MediaPreviewModal>

      <div className={styles.iconContainer}>
        <ActionIcon
          icon={EyeOutlined}
          onClick={handlePreviewImage}
          tooltipTitle="Preview image"
          visible={!!imageURL}
        />
        <ActionIcon
          icon={InteractionOutlined}
          onClick={handleApplyToAll}
          tooltipTitle="Apply to all"
          visible={!!onApplyToAll}
        />
        <ActionIcon
          icon={CopyOutlined}
          onClick={handleCopy}
          tooltipTitle="Copy to clipboard"
        />
        <ActionIcon
          icon={EditOutlined}
          onClick={handleEdit}
          tooltipTitle="Edit"
        />
      </div>
    </>
  );
};
