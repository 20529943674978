import { InboxOutlined } from "@ant-design/icons";
import { Modal, Upload, UploadProps } from "antd";
import { RcFile, UploadFile } from "antd/lib/upload/interface";
import { TemplateFile } from "shared/types/salesEnablement";
import {
  processFileUpload,
  validateTemplateFileName,
} from "./UploadDragger.utils";
import { toTemplateFile } from "screens/designStudio/utils";
import { Label } from "shared/components/text/Label";
import styles from "./UploadDragger.module.scss";

const getUploadFileAttrs = (file: UploadFile) => ({
  uid: file.uid,
  name: file.name,
  fileName: file.fileName,
  size: file.size,
});
type Props = {
  isEditing: boolean;
  fileList?: UploadFile<any>[];
  selectedFile?: TemplateFile;
  setSelectedFile?: (file?: TemplateFile) => void;
  setHtml?: (file?: TemplateFile) => void;
  allowDeletion: boolean;
  emptyFileError?: boolean;
};
const UploadDragger = (props: Props) => {
  const {
    selectedFile,
    setSelectedFile,
    isEditing,
    fileList,
    setHtml,
    emptyFileError,
  } = props;

  const uploadProps: UploadProps = {
    accept: ".zip,.pdf",
    maxCount: 1,
    beforeUpload: () => Promise.reject(false), // block upload. Upload will be done in onChange.
    onChange: async info => {
      // When deleting, fileList becomes an empty array
      const { file, fileList } = info;
      const isDeleting = fileList.length === 0;

      if (file && !isDeleting) {
        const { name } = file || {};
        if (!name) return;

        const templateFile = toTemplateFile({ ...getUploadFileAttrs(file) });

        setSelectedFile?.({
          ...templateFile,
          status: "uploading",
        });

        const { isValid, matches } = validateTemplateFileName({ name });

        if (!isValid) {
          setSelectedFile?.({
            ...templateFile,
            status: "error",
          });

          Modal.error({
            title: "Error",
            content: (
              <div>
                <span>Filename contains invalid characters.</span>
                <br />
                <b>{matches.join(", ")}</b>
              </div>
            ),
          });
          return;
        }

        try {
          const result = await processFileUpload(file as RcFile);

          setSelectedFile?.({
            ...templateFile,
            ...result,
            status: "done",
          });

          if (result && "html" in result)
            setHtml?.({ html: result.html, thumbnail: result.thumbnail });
        } catch (err) {
          setSelectedFile?.({
            ...templateFile,
            status: "error",
          });

          Modal.error({
            title: "Error",
            content: `${err}`,
          });
        }
      }
    },
    onRemove: async () => {
      if (!selectedFile) return;

      setSelectedFile?.(undefined);
    },
    showUploadList: {
      showRemoveIcon: props.allowDeletion,
    },
  };

  return isEditing ? (
    <div className={styles.container}>
      <Label style={{ paddingBottom: 0 }}>
        {selectedFile?.status === "uploading"
          ? `Uploading file`
          : `File uploaded`}
      </Label>
      <Upload {...uploadProps} fileList={fileList} />
    </div>
  ) : (
    <div className={styles.draggerWrapper}>
      <Label required className={styles.draggerLabel}>
        Please upload your file below and customize your components:
      </Label>
      <Upload.Dragger
        {...uploadProps}
        fileList={fileList}
        className={emptyFileError ? styles.draggerError : ""}
      >
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>

        <div>Click or drag to upload</div>
        <div>ZIP folder must contain IDML or HTML, plus all related assets</div>
      </Upload.Dragger>
      {emptyFileError ? (
        <span className={styles.draggerErrorMessage}>
          Please upload the file or click ”X” to remove this language
        </span>
      ) : null}
    </div>
  );
};
export default UploadDragger;
