import CommonFilterDrawer from "shared/components/filterDrawer/FilterDrawer";
import { fields } from "./fileDrawer/FormFields.schema";
import { useIsSalesEnablementAdmin } from "shared/hooks/useIsAdmin";
import { Template } from "shared/types/salesEnablement";
import {
  DateFilterField,
  FilterFields,
} from "shared/components/filterDrawer/useFilterFields";
import { useSalesEnablementContext } from "../hooks/SalesEnablementDataProvider";
import { deliveryMethods } from "../utils";
import { filterableMetrics } from "../helpers";
import { audienceFilterOptions } from "utils/helpers.salesEnablement";

type Props = {
  onClose: () => void;
  open: boolean;
};

const TemplatesFilterDrawer = ({ open, onClose }: Props) => {
  const {
    locations,
    status,
    salesCycle,
    materialType,
    language,
    customizable,
  } = fields;

  const isSalesEnablementAdmin = useIsSalesEnablementAdmin();
  const { templates: unmappedTemplates } = useSalesEnablementContext();
  const templates = filterableMetrics(unmappedTemplates);

  const filterFields: FilterFields<Template> = [
    {
      title: "Templates added by me",
      key: "createdBy",
      type: "check",
    },
    {
      title: "Popular Templates",
      key: "metrics",
      type: "check",
      tooltip: "Explore the top templates used by agents.",
    },
    {
      title: "Name",
      key: "name",
      type: "text",
    },
    {
      title: "Description",
      key: "description",
      type: "text",
    },
    {
      title: "Audience",
      key: "audience",
      options: audienceFilterOptions,
      type: "select",
    },
    {
      title: "Delivery Method",
      key: "deliveryMethods",
      options: deliveryMethods,
      type: "select",
    },
    {
      title: "States",
      key: "locations",
      options: locations.options,
      type: "select",
    },
    {
      title: "Language",
      key: "languages",
      options: language.options,
      type: "select",
    },
    {
      title: "Status",
      key: "status",
      options: isSalesEnablementAdmin
        ? status.options
        : [{ key: "new", value: "new", label: "New" }],
      type: "select",
    },
    {
      title: "Customizable",
      key: "customizable",
      options: customizable.options,
      type: "select",
    },
    {
      title: "Sales Cycle",
      key: "salesCycle",
      options: salesCycle.options,
      type: "select",
    },
    {
      title: "Material Type",
      key: "materialType",
      options: materialType.options,
      type: "select",
    },
    ...(isSalesEnablementAdmin
      ? [
          {
            title: "Expiration Date",
            key: "expirationDate",
            type: "date",
          } as DateFilterField<Template>,
        ]
      : []),
  ];

  return (
    <CommonFilterDrawer
      data={templates}
      filterKey="aflac-template"
      fields={filterFields}
      open={open}
      onClose={onClose}
    />
  );
};

export default TemplatesFilterDrawer;
