import { ExportDestination } from "../types";

export const isFinalExportStep = (
  currentStep: number,
  exportDestination: ExportDestination | null,
): boolean => {
  if (!exportDestination) return false;

  switch (exportDestination) {
    case ExportDestination.CSVDownload:
      return currentStep === 0;
    case ExportDestination.AdLibrary:
      return currentStep === 2;
    case ExportDestination.GoogleSpreadsheet:
      return currentStep === 1;
    default:
      return false;
  }
};
