import { TableProps, Tooltip } from "antd";
import { AdType, FillAdInfoTableData } from "./types";
import { fillAdInfoColumns } from "./constants";
import { FillAdInfoColumnTitle } from "./types";
import styles from "./ExportDrawer.module.scss";
import EditableCell from "screens/assetExporter/feedConfiguration/EditableCell";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { isCallToActionValue } from "./utils/callToActionValuesValidations";

const columnTitleTooltipContent: Partial<
  Record<FillAdInfoColumnTitle, string>
> = {
  [FillAdInfoColumnTitle.StoryMedia]: "Story Media Size: 1080x1920",
  [FillAdInfoColumnTitle.InFeedMedia]: "In-Feed media size: 1080x1080",
  [FillAdInfoColumnTitle.DisplayURL]:
    "Display URLs should not include / or http(s)",
};

const renderColumnTitle = (columnTitle: FillAdInfoColumnTitle) => {
  const tooltipContent = columnTitleTooltipContent[columnTitle];
  return (
    <div className={styles.mediaColumnTitleWrapper}>
      <span className={styles.mediaColumnTitle}>{columnTitle}</span>
      {tooltipContent && (
        <Tooltip trigger="hover" title={tooltipContent}>
          <QuestionCircleOutlined className={styles.mediaColumnIcon} />
        </Tooltip>
      )}
    </div>
  );
};

const renderMediaColumn = (columnTitle: FillAdInfoColumnTitle) => {
  const MediaColumn = (_: any, rowData: FillAdInfoTableData) => (
    <div className={styles.fillAdInfoThumbnailWrapper}>
      <img className={styles.thumbnail} src={rowData[columnTitle] ?? ""} />
    </div>
  );
  MediaColumn.displayName = `MediaColumn(${columnTitle})`;
  return MediaColumn;
};

const renderEditableColumn = (
  columnTitle: FillAdInfoColumnTitle,
  onSave: (value: string) => void,
  onApplyToAll: (value: string) => void,
) => {
  const EditableColumn = (
    _: any,
    rowData: FillAdInfoTableData,
    index: number,
  ) => (
    <EditableCell
      value={rowData[columnTitle] ?? ""}
      onSave={newValue => onSave(newValue.toString())}
      onApplyToAll={() => onApplyToAll(rowData[columnTitle] ?? "")}
      index={columnTitle}
      rowIdentifier={index.toString()}
      placeholderText="Click to add"
    />
  );
  EditableColumn.displayName = `EditableColumn(${columnTitle})`;
  return EditableColumn;
};

const hasRowError = (
  rowData: FillAdInfoTableData,
  columnTitle: FillAdInfoColumnTitle,
): boolean => {
  if (columnTitle !== FillAdInfoColumnTitle.CTAButton) return false;
  const columnValue = rowData[columnTitle];
  if (!columnValue) return false;
  return !isCallToActionValue(columnValue);
};

export const getColumns = (
  adType: AdType | null,
  onSave: (value: string) => void,
  onApplyToAll: (value: string) => void,
): TableProps<FillAdInfoTableData>["columns"] => {
  if (!adType) return [];

  return fillAdInfoColumns[adType].map(columnTitle => {
    const isMediaColumn =
      columnTitle === FillAdInfoColumnTitle.InFeedMedia ||
      columnTitle === FillAdInfoColumnTitle.StoryMedia;

    const columnRenderer = isMediaColumn
      ? renderMediaColumn(columnTitle)
      : renderEditableColumn(columnTitle, onSave, onApplyToAll);

    return {
      title: renderColumnTitle(columnTitle),
      dataIndex: columnTitle,
      className: styles.fillAdInfoColumn,
      fixed: isMediaColumn || columnTitle === FillAdInfoColumnTitle.AdName,
      render: columnRenderer,
      onCell: (rowData: FillAdInfoTableData) => ({
        className: hasRowError(rowData, columnTitle)
          ? styles.invalidCellContent
          : "",
      }),
    };
  });
};
