import { Modal } from "antd";
import { RcFile } from "antd/lib/upload";
import API from "services";

export const getFileInfo = async (
  id: string,
  filename: string,
  attrs: string,
) => {
  const { result, error } = await API.services.salesEnablement.getFileInfo(
    id,
    filename,
    attrs,
  );
  if (error) throw error.message;

  const { type } = result ?? {};
  if (!type) throw new Error("Unable to detect template type.");
  return type;
};
export const processFileUpload = async (file: RcFile) => {
  const {
    result: { url, bucket, filename, id },
  } = await API.services.salesEnablement.getPresignedUrl(file);
  const res = await API.services.salesEnablement.upload(file, url);
  if (!res.ok) throw new Error("Failed to upload file");

  const type = await getFileInfo(id, filename, "type");

  if (type === "indesign") {
    const { result, error } = await API.services.salesEnablement.parseIdml(
      bucket,
      filename,
      id,
    );
    if (!result || error) {
      Modal.error({
        title: "There was an error.",
        content: `${error?.message || "Failed to parse IDML"}`,
      });
      return;
    }
    const { pages, template, script, thumbnailUrl } = result;
    return {
      id,
      pages,
      type,
      thumbnail: thumbnailUrl,
      spMetadata: {
        entities: {
          template,
          script,
        },
      },
    };
  } else if (type === "pdf") {
    const { result, error } =
      await API.services.salesEnablement.processPdfTemplate({
        id,
        bucket,
        filename,
      });
    if (error) throw new Error(error.message);

    const { fileUrl, thumbnailUrls } = result;
    return {
      id,
      type,
      url: fileUrl,
      thumbnail: thumbnailUrls?.small,
    };
  } else {
    const { result } = await API.services.salesEnablement.parseHtml(
      bucket,
      `${id}/${filename}`,
      id,
    );
    return {
      id,
      thumbnail: result?.thumbnailUrl,
      ...result,
    };
  }
};

export const validateTemplateFileName = ({ name }: { name: string }) => {
  const encodings: Record<string, string> = {
    "+": "%2B",
    '"': "%22",
    "#": "%23",
    $: "%24",
    "&": "%26",
    ",": "%2C",
    ":": "%3A",
    ";": "%3B",
    "=": "%3D",
    "?": "%3F",
    "@": "%40",
  } as const;

  const matches = new Set<string>();

  const addMatch = (match: string) => {
    switch (match) {
      case "%20":
        matches.add("space");
        break;
      case "%22":
        matches.add("double quote");
        break;
      case ",":
        matches.add("comma");
        break;
      case ";":
        matches.add("semi colon");
        break;
      default:
        matches.add(match);
    }
  };

  const encodedFileName = encodeURI(name).replace(
    /(\+|#|\$|&|,|:|;|=|\?|@|\%20|\%22)/gim,
    match => {
      addMatch(match);
      return encodings[match];
    },
  );

  return {
    isValid: name === encodedFileName,
    matches: Array.from(matches),
  };
};
