import isEmpty from "lodash/isEmpty";
import orderBy from "lodash/orderBy";
import {
  FC,
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import actions from "redux/rootActions";

import {
  Button,
  Checkbox,
  Collapse,
  Divider,
  Tooltip,
  message,
  notification,
} from "antd";
import {
  AssetInstanceRecord,
  IAssetBuilderState,
  IAssetInstance,
  ICanvasVideo,
  ILauncherData,
  ISavedOrderState,
  ISelectedOffer,
  IWebIntegrationInstanceObject,
  IWebIntegrations,
  InstanceCheckboxDict,
  LauncherTabs,
} from "shared/types/assetBuilder";
import {
  INewOrder,
  INewOrderRecord,
  INewOrderState,
} from "shared/types/newOrders";

import BadgeCount from "shared/components/BadgeCount";
import {
  IRenderTemplateContext,
  useRenderTemplate,
} from "shared/components/RenderTemplateProvider";
import TemplatePreviewMemo from "shared/components/TemplatePreviewMemo";
import TemplateRenderProvider from "shared/components/contextAPI/shared/RenderTemplate";
import { IAssetExportQueryParamObj } from "shared/types/assetExport";
import { IConfigurationState } from "shared/types/configuration";
import { OfferType } from "shared/types/shared";
import { IUploadImageFormInput } from "shared/types/uploadManagement";
import { timezoneOffset } from "utils/helpers";
import {
  assetInstancesToAssetBuild,
  returnAssetName,
  returnInitialAssetsToExport,
  returnUploadMediaBatches,
} from "utils/helpers.asset";
import uuid from "uuid";
import { AssetLauncherIntegrationStatusModal } from "./launch/statusModal/AssetLauncherIntegrationStatusModal";

import {
  PauseCircleOutlined,
  PlayCircleOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { fabric } from "fabric";
import { Canvas } from "fabric/fabric-impl";
import { useLocation } from "react-router-dom";
import useFetchRenderTemplate from "shared/hooks/useFetchRenderTemplate";
import { IDimension } from "shared/types/designStudio";
import { UploadNewIntegration } from "./UploadNewIntegration";
import { RenderAssetInstance } from "./build/buildAssetList/RenderAssetInstance";

import "./AssetLauncher.scss";

interface IAssetLauncherProps {
  selectedOffers: ISelectedOffer[];
  order: INewOrder | null;
  forceRenderImages: boolean;
  assetInstances: AssetInstanceRecord;
  disableExport: IAssetBuilderState["disableExport"];
  errorMessage: IAssetBuilderState["errorMessage"];
  generalMessage: IAssetBuilderState["generalMessage"];
  processingExport: boolean;

  s3Url: string;
  config?: IConfigurationState["config"];
  selectedExportedAssets: InstanceCheckboxDict;

  willFireBatchUpload?: boolean;

  launcherSelectedTab: LauncherTabs;

  webIntegrations: IWebIntegrations[];
  refIndex: number | null;
  linkDimension: string | string[];
  setLinkDimension: (dimension: string | string[]) => void;
  setLauncherSelectedTab: (launcherSelectedTab: LauncherTabs) => void;
  setNumInstancesInAssetLauncher: (numInstancesInAssetLauncher: number) => void;

  getWebIntegrations: (domain: string) => void;
  createWebIntegration: (webIntegrationData: ILauncherData) => void;
  deleteWebIntegration: (webIntegrationData: ILauncherData) => void;
  openStatusModal?: boolean;
  setOpenStatusModal?: () => void;
  launcherImages: string[];
  createWebIntegrationResult: IWebIntegrationInstanceObject;

  launcherData: ILauncherData;
  setLauncherData: (launcherData: ILauncherData) => void;

  templatesOrderedByDimension: IAssetInstance[];

  goLiveButtonClicked: boolean;
  setGoLiveButtonClicked: (goLiveButtonClicked: boolean) => void;

  selectedLauncherInstances: Record<number, IAssetInstance>;
  setSelectedLauncherInstances: (
    selectedLauncherInstances: Record<number, IAssetInstance>,
  ) => void;

  currOrderOfSelectedLauncherInstances: number[];
  setCurrOrderOfSelectedLauncherInstances: (
    currOrderOfSelectedLauncherInstances: number[],
  ) => void;
  openLaunchModal: boolean;
}

interface IAssetLauncherHandlers {
  toggleForceRenderImages: (force: boolean) => void;
  updateNewOrder: (updateNewOrder: Partial<INewOrderRecord>) => Promise<void>;
  assetInstanceCounter: () => void;
  resetAssetInstanceCounter: () => void;
  setAssetInstanceComparator: (assetInstance: AssetInstanceRecord) => void;
  fetchOrderState: () => void;
  updateExportedAssetsArr: (
    assetNames: Record<string, Record<string, string[]>>,
  ) => void;

  toggleWillBatchUpload?: (value: boolean) => void;

  setLauncherSelectedTab: (launcherSelectedTab: LauncherTabs) => void;
  setNumInstancesInAssetLauncher: (numInstancesInAssetLauncher: number) => void;
  commitOrder: () => Promise<void>;
  updateAssetInstances: (
    assetType: string,
    size: string,
    instances: IAssetInstance[] | null,
  ) => void;
  generateImagesForLauncherPage: (
    images: string[],
    templateAndDimensionsArr: string[],
  ) => void;
  setOpenLaunchModal: (openLaunchModal?: boolean) => void;
}

type AssetInstanceExport = {
  name: string;
  isCustom: boolean;
};

message.config({ maxCount: 1 });

const AssetLauncher: FC<
  IAssetLauncherProps & IAssetLauncherHandlers
> = props => {
  const { search } = useLocation();
  const assetExportQueryObj = Object.fromEntries(
    new URLSearchParams(search),
  ) as IAssetExportQueryParamObj;

  const renderTemplateContext = useRenderTemplate();

  const [processingUpload, setProcessingUpload] = useState<boolean>(false);

  const [namesOfAssetsPlayingVideo, setNamesOfAssetsPlayingVideo] =
    useState<InstanceCheckboxDict>({});

  const [sortedWebInts, setSortedWebInts] = useState<IWebIntegrations[]>([]);
  const focusRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setTimeout(() => {
      if (focusRef && focusRef.current) {
        focusRef.current.scrollIntoView({
          block: "center",
          inline: "start",
          behavior: "smooth",
        });
      }
    }, 500);
  }, [props.refIndex]);

  useEffect(() => {
    if (!props.webIntegrations) return;
    const sortedWebInts = orderBy(
      props.webIntegrations,
      ["instanceStatus", "lastModifiedDate"],
      ["asc", "desc"],
    );
    setSortedWebInts(sortedWebInts);
  }, [props.webIntegrations]);

  useEffect(() => {
    if (sortedWebInts.length !== 0) {
      props.getWebIntegrations(sortedWebInts[0].domain);
    } else {
      props.createWebIntegrationResult?.domain &&
        props.getWebIntegrations(props.createWebIntegrationResult.domain);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.createWebIntegrationResult]);

  useEffect(() => {
    if (props.goLiveButtonClicked) {
      getImagesFromInstances();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.goLiveButtonClicked]);

  useEffect(() => {
    if (
      props.launcherData.type === "singleImage" &&
      Object.keys(props.selectedLauncherInstances).length > 1
    ) {
      props.setSelectedLauncherInstances({});
      props.setCurrOrderOfSelectedLauncherInstances([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.launcherData.type]);

  useEffect(() => {
    if (!props.disableExport) {
      message.success("Assets are ready to be launched");
    } else {
      message.warning(
        "All assets must be pre-loaded before Launch option is made available ...",
      );
    }
  }, [props.disableExport]);

  useEffect(() => {
    props.generalMessage && message.info(props.generalMessage);
  }, [props.generalMessage]);

  useEffect(() => {
    props.errorMessage && message.error(props.errorMessage);
  }, [props.errorMessage]);

  useEffect(() => {
    if (
      !props.assetInstances ||
      Object.keys(props.assetInstances).length < 1 ||
      Object.keys(props.selectedExportedAssets).length >= 1
    ) {
      return;
    }
    const initalAssetsToExport = returnInitialAssetsToExport(
      props.assetInstances,
      props.selectedOffers,
      true,
      props.order,
    );

    props.updateExportedAssetsArr(initalAssetsToExport);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.assetInstances, props.selectedExportedAssets]);

  useEffect(() => {
    props.order?.parentFileToken &&
      props.order?.documentID &&
      setProcessingUpload(!processingUpload);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.order?.parentFileToken, props.order?.documentID]);

  const handleInstanceCheckboxToggle = ({
    checkedValue,
    assetType,
    dimension,
    checkedKey,
    isForPlayingVideo,
  }: {
    checkedValue: boolean;
    assetType: string;
    dimension: string;
    checkedKey: string;
    isForPlayingVideo?: boolean;
  }) => {
    const newAssetInstanceNames = {
      ...(isForPlayingVideo
        ? (JSON.parse(
            JSON.stringify(namesOfAssetsPlayingVideo),
          ) as typeof namesOfAssetsPlayingVideo)
        : (JSON.parse(
            JSON.stringify(props.selectedExportedAssets),
          ) as typeof props.selectedExportedAssets)),
    };

    if (!newAssetInstanceNames?.[assetType]) {
      newAssetInstanceNames[assetType] = {};
    }

    if (!newAssetInstanceNames[assetType][dimension]) {
      newAssetInstanceNames[assetType][dimension] = [];
    }

    const selectedInstanceNamesToUse = isForPlayingVideo
      ? namesOfAssetsPlayingVideo
      : props.selectedExportedAssets;

    if (!checkedValue) {
      newAssetInstanceNames[assetType][dimension] = selectedInstanceNamesToUse[
        assetType
      ][dimension].filter(assetName => assetName !== checkedKey);
    } else {
      newAssetInstanceNames[assetType][dimension].push(checkedKey);
    }

    if (isForPlayingVideo) {
      setNamesOfAssetsPlayingVideo(newAssetInstanceNames);
    } else {
      props.updateExportedAssetsArr(newAssetInstanceNames);
    }
  };

  const returnInstanceCountDict = (
    selectedInstances: AssetInstanceRecord | InstanceCheckboxDict,
  ) => {
    const counterDictionary = {} as Record<string, number>;
    // tslint:disable-next-line: forin
    for (const assetType in selectedInstances) {
      counterDictionary[assetType] = 0;
      const currentCheckboxGroup = selectedInstances[assetType];
      for (const dimension in currentCheckboxGroup) {
        if (currentCheckboxGroup[dimension].length < 1) {
          continue;
        }
        const currentInstances = selectedInstances[assetType][dimension];
        counterDictionary[assetType] += currentInstances.length;
      }
    }

    return counterDictionary;
  };

  const isVideo = (objs: fabric.Object[]) =>
    !!objs.find((obj: any) => !!obj.customData?.videoSrc);

  const getCanvasData = (
    canvases: Record<string, Canvas> | undefined,
  ): ICanvasVideo[] => {
    if (!canvases) return [];
    return Object.keys(canvases).reduce((acc, filename) => {
      const canvas = canvases[filename];
      const dimension: IDimension = {
        width: canvas.getWidth(),
        height: canvas.getHeight(),
      };
      const json = canvas.toJSON([
        "customType",
        "customData",
        "name",
      ]) as unknown as {
        objects: fabric.Object[];
      };

      acc.push({
        json,
        isVideo: isVideo(json.objects),
        filename,
        dimension,
      });

      return acc;
    }, [] as any);
  };

  const getVideoStillUri = (canvasData: ICanvasVideo) => {
    const canvas = new fabric.Canvas(null);
    canvas.width = canvasData.dimension.width;
    canvas.height = canvasData.dimension.height;

    return new Promise<string>(resolve => {
      canvas.loadFromJSON(canvasData.json, () => {
        resolve(canvas.toDataURL());
      });
    });
  };

  // TODO: reimplement the export function as a launch when the other stuff is complete
  // add a action for it too

  const getImageDataFromCanvas = async (
    renderTemplateContext: IRenderTemplateContext | null,
    exportingFilenames: AssetInstanceExport[],
    wfProjectNumber: string,
  ) => {
    const canvasImages =
      exportingFilenames.filter(e => !e.isCustom).map(e => e.name) || [];
    const customFilesOrder =
      exportingFilenames.filter(e => e.isCustom).map((_, idx) => idx) || [];

    const canvases = renderTemplateContext?.getExportingCanvases(canvasImages);

    const canvasData = getCanvasData(canvases);

    let toDataResult = await Promise.all(
      canvasData.map(async (canvas, idx) => {
        if (canvas.isVideo) {
          const base64 = await getVideoStillUri(canvas);
          return {
            fileName: canvas.filename,
            base64,
          };
        }
        const imageData = (await renderTemplateContext?.toDataURLsAndFilename(
          [canvasImages[idx]],
          "jpeg",
          undefined,
          true,
        )) || [{ fileName: "", base64: "" }];

        return imageData[0];
      }),
    );

    if (customFilesOrder.length > 0) {
      for (const selectedLauncherInstanceOrder of props.currOrderOfSelectedLauncherInstances) {
        const customIntegrationImages = canvasList
          .filter(
            canvas =>
              canvas.originalIndexOrder === selectedLauncherInstanceOrder &&
              canvas.imageDataUrl,
          )
          .map(assetInstance => {
            return {
              fileName: `${uuid()}__${selectedLauncherInstanceOrder}`,
              base64: assetInstance.imageDataUrl!,
            };
          });
        toDataResult = toDataResult.concat(customIntegrationImages);
      }
    }

    const data: string[] = [];
    const newAssetArr: string[] = [];

    toDataResult = toDataResult?.filter(r => !!r);

    if (toDataResult?.length === 0) {
      toDataResult = [{ fileName: "", base64: "" }];
    }

    toDataResult.forEach(res => {
      data.push(res.base64);
      newAssetArr.push(
        wfProjectNumber
          ? `${props.order?.dealer_name || ""}${wfProjectNumber}_${
              res.fileName
            }`
          : res.fileName,
      );
    });

    props.generateImagesForLauncherPage(
      data,
      exportingFilenames.map(filename => filename.name.replace("%", "percent")),
    );
  };

  const areAutoScrollingSettingsValid = () => {
    const transitionTime = props.launcherData.transitionTime || "";
    if (!transitionTime) {
      props.setLauncherData({
        ...props.launcherData,
        transitionTime: "0.75",
      });
    }
    const timeUntilNextTransition =
      props.launcherData.timeUntilNextTransition || "";
    if (!timeUntilNextTransition) {
      props.setLauncherData({
        ...props.launcherData,
        timeUntilNextTransition: "4",
      });
    }
    return !(
      transitionTime[transitionTime.length - 1] === "." ||
      timeUntilNextTransition[timeUntilNextTransition.length - 1] === "."
    );
  };

  const getImagesFromInstances = async () => {
    //
    if (!renderTemplateContext || !props.order) {
      return;
    }

    if (
      props.launcherData.isAutoScrollEnabled &&
      !areAutoScrollingSettingsValid()
    ) {
      message.error(
        "Make sure carousel auto-scroll settings end with a number",
      );
      props.setOpenLaunchModal(false);
      return;
    }

    const exportingFilenameObject = returnInitialAssetsToExport(
      props.assetInstances,
      props.selectedOffers,
      true,
      props.order,
    );
    let exportingFilenames: string[] = [];

    // Selection process is not needed in s3 exports for now
    for (const assetType in exportingFilenameObject) {
      for (const dimension in exportingFilenameObject[assetType]) {
        exportingFilenames = [
          ...exportingFilenames,
          ...exportingFilenameObject[assetType][dimension],
        ];
      }
    }

    // select all the website(or current) names
    const currTabInstanceNames: string[] = [];
    for (let i = 0; i < exportingFilenames.length; i++) {
      const name = exportingFilenames[i];
      // the first part of the name has to be the same as the current tab (ex. social, website, email etc.)
      if (name.split("_")[0] === props.launcherSelectedTab) {
        const splitName = name.split("_");
        splitName[splitName.length - 1] = `${currTabInstanceNames.length}`;

        currTabInstanceNames.push(splitName.join("_"));
      }
    }

    // for each tab instance name, we want to take only the indices that are in currOrderOfSelectedLauncherInstances (aka selected assets)
    const selectedCurrTabInstancedNames: AssetInstanceExport[] = [];
    for (const index of props.currOrderOfSelectedLauncherInstances) {
      selectedCurrTabInstancedNames.push({
        name: currTabInstanceNames[index],
        isCustom: !!props.selectedLauncherInstances[index]?.isCustomImage,
      });
    }

    if (selectedCurrTabInstancedNames.length < 1) {
      message.warn("No assets were selected for export.");
      return;
    }

    const { wfProjectNumber } = props.order || {
      wfID: "",
      wfProjectName: "",
      wfProjectNumber: "",
      wfFullProjectName: "",
      dealer_code: "",
    };

    await getImageDataFromCanvas(
      renderTemplateContext,
      selectedCurrTabInstancedNames,
      wfProjectNumber,
    );
  };

  const exportAssetsToS3 = async () => {
    if (!renderTemplateContext || !props.order) {
      return;
    }

    const { executionid: executionId, start = "0" } = assetExportQueryObj;

    // executionId = <ORDERID_TIMESTAMP>
    if (!executionId) {
      notification.error({
        message: "Could not export assets",
        description: "An execution id is missing from the page's URL",
        placement: "bottomRight",
      });
      props.toggleWillBatchUpload?.(false);
      return;
    }

    const { id: orderId } = props.order;
    const exportingFilenameObject = returnInitialAssetsToExport(
      props.assetInstances,
      props.selectedOffers,
      true,
      props.order,
    );
    let exportingFilenames: string[] = [];

    // Selection process is not needed in s3 exports for now
    for (const assetType in exportingFilenameObject) {
      for (const dimension in exportingFilenameObject[assetType]) {
        exportingFilenames = [
          ...exportingFilenames,
          ...exportingFilenameObject[assetType][dimension],
        ];
      }
    }

    const toDataResult: Array<{
      fileName: string;
      base64: string;
    }> = await renderTemplateContext.toDataURLsAndFilename(exportingFilenames);

    const data: string[] = [];
    const newAssetArr: string[] = [];
    toDataResult.forEach(res => {
      data.push(res.base64);
      newAssetArr.push(`${orderId}_${res.fileName}`);
    });

    // Sample path: /batch-export/default/<ORDERID>/<EXECUTIONID>/<FLATTENEDINDEX>.<MIMETYPE>
    const destination = `batch-export/default/${orderId}/${executionId}`;

    const uploadMediaObjs: IUploadImageFormInput[] = toDataResult.map(
      (assetObj, index) => ({
        file: assetObj.base64,
        filename: `${parseInt(start) + index}.png`, // will use png for now
        type: "image/png",
      }),
    );

    const uploadMediaBatches: IUploadImageFormInput[][] =
      returnUploadMediaBatches(uploadMediaObjs, 5);

    for (const batch of uploadMediaBatches) {
      if (batch.length < 1) {
        continue;
      }
      await renderTemplateContext.uploadMedia?.(batch, destination);
    }

    props.toggleWillBatchUpload?.(false);
  };

  useEffect(() => {
    if (!props.willFireBatchUpload) return;

    exportAssetsToS3();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.willFireBatchUpload]);

  const [canvasList, setCanvasList] = useState<
    typeof props.templatesOrderedByDimension
  >([]);

  const [templateCopyForCustomUpload, setTemplateCopyForCustomUpload] =
    useState(props.templatesOrderedByDimension[0]);
  const [isCanvasListSet, setIsCanvasListSet] = useState(false);

  useEffect(() => {
    if (!isCanvasListSet && props.templatesOrderedByDimension.length > 0) {
      const tempList = props.templatesOrderedByDimension.map(
        (instance, idx) => ({
          ...instance,
          originalIndexOrder: idx,
        }),
      );
      setCanvasList(tempList);
      setTemplateCopyForCustomUpload(props.templatesOrderedByDimension[0]);
      setIsCanvasListSet(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.templatesOrderedByDimension]);

  const allInstanceCountDict = returnInstanceCountDict(props.assetInstances);

  const supportVideos = props.config?.featureFlags?.supportVideos;

  // const assetInstancesByDimension =
  //   props.assetInstances[props.launcherSelectedTab];
  // const orderedDimensions = orderBy(Object.keys(assetInstancesByDimension));
  const instanceCount = allInstanceCountDict[props.launcherSelectedTab];
  props.setNumInstancesInAssetLauncher(instanceCount);
  const assetType = props.launcherSelectedTab;
  const dimensions = Object.keys(props.assetInstances[assetType] || {});

  const [currentSelectedDimension, setCurrentSelectedDimension] =
    useState<string>("");

  const processInstances = useCallback(
    (
      instancesByDimension: Record<string, IAssetInstance[]>,
      dimensions: string[],
    ): Record<string, IAssetInstance[]> => {
      const record: Record<string, IAssetInstance[]> = {};
      dimensions.forEach(dimension => {
        const instances = instancesByDimension[dimension];
        record[dimension] = instances.map(
          (instance: IAssetInstance, idx: number) => {
            return { ...instance, originalIndexOrder: idx };
          },
        );
      });
      return record;
    },
    [],
  );

  const instancesByDimension = processInstances(
    props.assetInstances[assetType],
    dimensions,
  );
  useEffect(() => {
    if (props.currOrderOfSelectedLauncherInstances.length === 0) {
      setCurrentSelectedDimension("");
    }
  }, [props.currOrderOfSelectedLauncherInstances]);

  const assetBuild = useMemo(
    () =>
      assetInstancesToAssetBuild(props.assetInstances, props.selectedOffers),
    [props.assetInstances, props.selectedOffers],
  );

  const cache = useFetchRenderTemplate({
    order: props.order,
    instances: assetBuild.instances,
    config: props.config,
  });

  const createCustomTemplateObject = (
    base64: string,
    width: number,
    height: number,
    templateCopy: IAssetInstance,
    canvasList: IAssetInstance[],
  ) => {
    return {
      ...templateCopy,
      id: uuid(),
      template: {
        ...templateCopy.template,
        id: uuid(),
        name: "Custom Integration",
        artboard: {
          ...templateCopy.template?.artboard,
          width,
          height,
        },
      },
      imageDataUrl: base64,
      originalIndexOrder: canvasList.length,
      isCustomImage: true,
    } as IAssetInstance;
  };

  const removeCustomIntegration = (
    assetInstance: IAssetInstance,
    dimension: string,
  ) => {
    const nextCanvasList = instancesByDimension[dimension].filter(
      instance => instance.id !== assetInstance.id,
    );
    setCanvasList(nextCanvasList);
    props.updateAssetInstances(assetType, dimension, nextCanvasList);
    props.commitOrder();
  };

  const onNewIntegrationUpload = async ({
    width,
    height,
    base64,
    dimension,
  }: {
    width: number;
    height: number;
    base64: string;
    dimension: string;
  }) => {
    const nextCanvasList = [
      ...instancesByDimension[dimension],
      createCustomTemplateObject(
        base64,
        width,
        height,
        templateCopyForCustomUpload,
        canvasList,
      ),
    ];
    setCanvasList(nextCanvasList);
    props.updateAssetInstances(assetType, dimension, nextCanvasList);
    props.commitOrder();
  };

  return (
    <div className="launch-container">
      {
        <AssetLauncherIntegrationStatusModal
          webIntegrations={sortedWebInts}
          openStatusModal={props.openStatusModal}
          toggleStatusModal={() => {
            props.setOpenStatusModal && props.setOpenStatusModal();
          }}
          createWebIntegration={props.createWebIntegration}
          deleteWebIntegration={props.deleteWebIntegration}
          getWebIntegrations={props.getWebIntegrations}
          createWebIntegrationResult={props.createWebIntegrationResult}
          savedOrder={props.order}
          commitOrder={props.commitOrder}
          updateNewOrder={props.updateNewOrder}
        ></AssetLauncherIntegrationStatusModal>
      }
      {(props.order?.wfFullProjectName ||
        (props.order &&
          props.order?.expiresAt <
            Math.round(Date.now() + timezoneOffset))) && (
        <div>
          <div>
            <span data-cy="available-assets-title">
              Available Assets: {instanceCount}
            </span>
            <br />
          </div>
          <div>
            <Collapse
              className="collapse-container asset-launcher"
              activeKey={props.linkDimension}
              onChange={dim => {
                props.setLinkDimension(dim);
              }}
            >
              {dimensions.map(dimension => {
                const dimensionsNotMatching =
                  (currentSelectedDimension &&
                    currentSelectedDimension !== dimension) ||
                  false;

                const allSelected = instancesByDimension[dimension].every(
                  template =>
                    Object.keys(props.selectedLauncherInstances).includes(
                      template.originalIndexOrder?.toString() || "",
                    ),
                );

                const anySelected = instancesByDimension[dimension].some(
                  template =>
                    Object.keys(props.selectedLauncherInstances).includes(
                      template.originalIndexOrder?.toString() || "",
                    ),
                );

                const countSelected = instancesByDimension[dimension].filter(
                  template =>
                    Object.keys(props.selectedLauncherInstances).includes(
                      template.originalIndexOrder?.toString() || "",
                    ) && !dimensionsNotMatching,
                ).length;
                return (
                  <Collapse.Panel
                    key={dimension}
                    forceRender={true}
                    className={
                      dimensionsNotMatching ? "disabled-collapsable" : ""
                    }
                    header={
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Tooltip
                          title="Toggle selection of all instances with this dimension"
                          placement="bottomLeft"
                          trigger="hover"
                          className="asset-tooltip"
                        >
                          <Checkbox
                            style={{
                              paddingBottom: 0,
                              color: "white",
                            }}
                            onClick={event => event.stopPropagation()}
                            disabled={
                              props.launcherData.type === "singleImage" ||
                              dimensionsNotMatching
                            }
                            indeterminate={
                              anySelected &&
                              !allSelected &&
                              !dimensionsNotMatching
                            }
                            checked={allSelected}
                            onChange={() => {
                              setCurrentSelectedDimension(dimension);
                              const tempObj = {
                                ...props.selectedLauncherInstances,
                              };
                              if (anySelected) {
                                instancesByDimension[dimension].forEach(
                                  template => {
                                    delete tempObj[
                                      template.originalIndexOrder!
                                    ];
                                  },
                                );
                                const newOrder = [
                                  ...props.currOrderOfSelectedLauncherInstances,
                                ].filter(index => {
                                  for (const template of instancesByDimension[
                                    dimension
                                  ]) {
                                    if (
                                      template.originalIndexOrder! === index
                                    ) {
                                      return false;
                                    }
                                  }
                                  return true;
                                });
                                props.setCurrOrderOfSelectedLauncherInstances(
                                  newOrder,
                                );
                              } else {
                                const addedOrder: number[] = [];
                                instancesByDimension[dimension].reduce(
                                  (acc: any, instance: IAssetInstance) => {
                                    const index = instance.originalIndexOrder!;
                                    acc[index] = instance;
                                    addedOrder.push(index);
                                    return acc;
                                  },
                                  tempObj,
                                );
                                props.setCurrOrderOfSelectedLauncherInstances([
                                  ...props.currOrderOfSelectedLauncherInstances,
                                  ...addedOrder,
                                ]);
                              }
                              props.setSelectedLauncherInstances(tempObj);
                            }}
                          />
                          <span
                            style={{
                              color: "white",
                              marginLeft: "0.75em",
                              opacity:
                                props.launcherData.type === "singleImage"
                                  ? 0.75
                                  : 1,
                            }}
                          >
                            {dimension}
                            <BadgeCount count={countSelected} />
                          </span>
                        </Tooltip>
                      </div>
                    }
                  >
                    {instancesByDimension[dimension].map(
                      (instance: IAssetInstance) => {
                        const index = instance.originalIndexOrder!;
                        const { template } = instance;
                        // Old IAssetInstance type has list of offers.
                        // This has done based on the assumption that we can selected multiple offers for one instance (like more than one carcut image in a template should have more than one offers).
                        // This has been changed. One offer can be selected for each instance.
                        // So when extracting the offer from IAssetInstance, we take the first one
                        const vins = Object.keys(instance.offers);
                        if (vins.length !== 1) {
                          message.warning(
                            `Data corruption for order id: ${props.order?.id}`,
                          );
                        }

                        const [vin] = vins;
                        const offerData = props.selectedOffers.find(
                          selected => selected.offerData.vin === vin,
                        )?.offerData;
                        const offerTypes = Object.keys(
                          instance.offers[vin].offerTypes,
                        ).filter(
                          key =>
                            instance.offers[vin].offerTypes[key as OfferType],
                        ) as OfferType[];

                        if (!offerData || !offerTypes) return null;

                        const offer = {
                          offerData,
                          offerTypes,
                        };

                        if (isEmpty(offer)) {
                          return null;
                        }
                        const { year, make, model, trim } = offerData;

                        const title = `${year} ${make} ${model} ${
                          trim ? trim : ""
                        }`;

                        const dimension = `${instance.template?.artboard.width} x ${instance.template?.artboard.height}`;

                        const exportFilename = returnAssetName(
                          dimension,
                          offerData,
                          vin,
                          assetType,
                          index,
                          props.order,
                          true,
                        );

                        // This below filename has to be unique to each canvas because there might be a case where
                        //  user selects same template, offer and offer types. Then there will be two or more canvases
                        //  that has same key and exported images will be overwritten with the last canvas that comes in.
                        // NOTE: instance.offers has to have ONLY one key (vin) because user can select ONLY one offer for each template.

                        const showPlayButton =
                          supportVideos && template?.mediaType === "mp4";

                        const playVideos =
                          namesOfAssetsPlayingVideo?.[assetType]?.[
                            dimension
                          ]?.includes(exportFilename);
                        const instanceIndex =
                          props.currOrderOfSelectedLauncherInstances.findIndex(
                            instanceIndex => instanceIndex === index,
                          );
                        return (
                          <div
                            data-cy="launch-instance"
                            className={`launch-instance ${
                              instance.originalIndexOrder! in
                                props.selectedLauncherInstances &&
                              !dimensionsNotMatching
                                ? "selected"
                                : ""
                            }`}
                            style={
                              dimensionsNotMatching
                                ? { cursor: "not-allowed" }
                                : {}
                            }
                            key={`fragment-key ${dimension}-${
                              offer.offerData.vin
                            }-${instance.originalIndexOrder!}`}
                            ref={
                              instanceIndex === props.refIndex
                                ? focusRef
                                : undefined
                            }
                            onClick={() => {
                              if (dimensionsNotMatching) return;
                              if (
                                props.launcherData.type === "singleImage" &&
                                Object.keys(props.selectedLauncherInstances)
                                  .length === 1 &&
                                !props.selectedLauncherInstances[
                                  instance.originalIndexOrder!
                                ]
                              ) {
                                props.setSelectedLauncherInstances({
                                  [instance.originalIndexOrder!]: instance,
                                });
                                props.setCurrOrderOfSelectedLauncherInstances([
                                  instance.originalIndexOrder!,
                                ]);
                                return;
                              }

                              // add or remove from selectedLauncherInstances
                              if (index in props.selectedLauncherInstances) {
                                const tempObj = {
                                  ...props.selectedLauncherInstances,
                                };
                                delete tempObj[instance.originalIndexOrder!];
                                props.setSelectedLauncherInstances(tempObj);
                              } else {
                                props.setSelectedLauncherInstances({
                                  ...props.selectedLauncherInstances,
                                  [instance.originalIndexOrder!]: instance,
                                });
                                setCurrentSelectedDimension(dimension);
                              }

                              // add or remove from currOrderOfSelectedLauncherInstances

                              if (instanceIndex >= 0) {
                                // if has it, remove it and maintain order.
                                props.setCurrOrderOfSelectedLauncherInstances(
                                  props.currOrderOfSelectedLauncherInstances
                                    .slice(0, instanceIndex)
                                    .concat(
                                      props.currOrderOfSelectedLauncherInstances.slice(
                                        instanceIndex + 1,
                                      ),
                                    ),
                                );
                              } else {
                                // add to the end
                                props.setCurrOrderOfSelectedLauncherInstances(
                                  [
                                    ...props.currOrderOfSelectedLauncherInstances,
                                  ].concat([instance.originalIndexOrder!]),
                                );
                              }
                            }}
                          >
                            <Fragment
                              key={`fragment-key-${dimension}-${
                                offer.offerData.vin
                              }-${instance.originalIndexOrder!}`}
                            >
                              <Divider dashed={false}>
                                {instance.imageDataUrl
                                  ? "Custom Integration"
                                  : title}
                              </Divider>

                              <div style={{ paddingBottom: "40px" }}>
                                <div style={{}}>
                                  <Checkbox
                                    style={{
                                      padding: "0.3em",
                                      marginLeft: "1.5em",
                                    }}
                                    checked={
                                      props.currOrderOfSelectedLauncherInstances.includes(
                                        index,
                                      ) && !dimensionsNotMatching
                                    }
                                  />
                                  {props.currOrderOfSelectedLauncherInstances.includes(
                                    index,
                                  ) &&
                                    !dimensionsNotMatching && (
                                      <span
                                        style={{
                                          marginLeft: "1em",
                                          fontWeight: "bold",
                                        }}
                                      >{`Instance ${
                                        props.currOrderOfSelectedLauncherInstances.indexOf(
                                          index,
                                        ) + 1
                                      }`}</span>
                                    )}
                                </div>
                                <div
                                  className="dimension"
                                  style={{
                                    width: "98%",
                                    float: "right",
                                  }}
                                >
                                  <div style={{ display: "flex" }}>
                                    <div>
                                      <h3>
                                        <u>Dimensions</u>
                                      </h3>
                                      <h5>Original: {dimension}</h5>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div
                                key={`template-preview-${offer.offerData.vin}`}
                                className="preview-wrapper"
                              >
                                <div
                                  style={
                                    dimensionsNotMatching
                                      ? { cursor: "not-allowed" }
                                      : {}
                                  }
                                >
                                  <RenderAssetInstance
                                    isCustomIntegration={
                                      !!instance.isCustomImage
                                    }
                                    customIntegration={
                                      <img
                                        src={instance.imageDataUrl}
                                        style={{ padding: "15px" }}
                                      />
                                    }
                                  >
                                    {cache && (
                                      <TemplateRenderProvider
                                        config={props.config}
                                        offers={props.selectedOffers}
                                      >
                                        <TemplatePreviewMemo
                                          renderTemplateCache={cache}
                                          template={template}
                                          offer={offer}
                                          order={props.order}
                                          selectedOffers={props.selectedOffers}
                                          displayLabelBox={false}
                                          disclosure={instance.disclosure}
                                          objectVisibilities={
                                            instance.visibilities
                                          }
                                          logoSubstitutions={
                                            instance.logoSubstitutions
                                          }
                                          displayCanvasObjects={false}
                                          lifestyleImageUrl={
                                            instance.lifestyleImageUrl
                                          }
                                          lifestyleFabricImageJson={
                                            instance.lifestyleFabricImageJson
                                          }
                                          assetInstanceCounter={() => {
                                            props.assetInstanceCounter();
                                          }}
                                          exportFilename={exportFilename}
                                          supportVideos={supportVideos}
                                          playVideos={playVideos}
                                        />
                                      </TemplateRenderProvider>
                                    )}
                                  </RenderAssetInstance>
                                  {/* TODO: add the 3 buttons from the mockup here later */}
                                  {showPlayButton ||
                                    (instance.imageDataUrl && (
                                      <Divider>
                                        {instance.imageDataUrl && (
                                          <Button
                                            size="large"
                                            shape="circle"
                                            icon={<DeleteOutlined />}
                                            onClick={() =>
                                              removeCustomIntegration(
                                                instance,
                                                dimension,
                                              )
                                            }
                                            type="primary"
                                            danger
                                          ></Button>
                                        )}
                                        {showPlayButton && (
                                          <Button
                                            type="primary"
                                            className="control-button"
                                            size="large"
                                            shape="circle"
                                            icon={
                                              playVideos ? (
                                                <PauseCircleOutlined />
                                              ) : (
                                                <PlayCircleOutlined />
                                              )
                                            }
                                            title={`${
                                              playVideos ? "Stop" : "Start"
                                            } videos in this instance`}
                                            onClick={() => {
                                              handleInstanceCheckboxToggle({
                                                checkedValue: !playVideos,
                                                assetType,
                                                dimension,
                                                checkedKey: exportFilename,
                                                isForPlayingVideo: true,
                                              });
                                            }}
                                          />
                                        )}
                                      </Divider>
                                    ))}
                                </div>
                              </div>
                            </Fragment>
                          </div>
                        );
                      },
                    )}
                    <UploadNewIntegration
                      disabled={dimensionsNotMatching}
                      dimension={dimension}
                      onFileSelected={async ({ base64, width, height }) => {
                        await onNewIntegrationUpload({
                          base64,
                          width,
                          height,
                          dimension,
                        });
                      }}
                    />
                  </Collapse.Panel>
                );
              })}
            </Collapse>
          </div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state: any) => {
  const {
    assetBuilder: { savedOrder },
    configuration: { config },
  } = state as {
    assetBuilder: IAssetBuilderState;
    newOrders: INewOrderState;
    configuration: IConfigurationState;
  };

  const { assetBuilder } = state;
  const {
    s3Url,
    canvasIDArr,
    processingExport,
    generalMessage,
    errorMessage,
    disableExport,
    getWebIntegrationsResult,
    createWebIntegrationResult,
  } = assetBuilder as IAssetBuilderState;

  return {
    selectedOffers: savedOrder?.selectedOffers || [],
    s3Url,
    order: savedOrder && (savedOrder as ISavedOrderState).selectedOrder,
    canvasIDArr,
    processingExport,
    generalMessage,
    errorMessage,
    disableExport,
    config,
    webIntegrations: getWebIntegrationsResult,
    createWebIntegrationResult,
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, any>) => {
  return {
    uploadCanvasImage: (canvas: string, templateAndDimensions: string) => {
      dispatch(
        actions.assetBuilder.uploadCanvasImage(canvas, templateAndDimensions),
      );
    },
    updateExportedAssetsArr: (
      assetNames: Record<string, Record<string, string[]>>,
    ) => {
      dispatch(actions.assetBuilder.updateExportedAssetsArr(assetNames));
    },
    updateAssetInstances: (
      assetType: string,
      size: string,
      instances: IAssetInstance[] | null,
    ) => {
      dispatch(
        actions.assetBuilder.updateAssetInstances({
          assetType,
          size,
          instances,
        }),
      );
    },
    updateNewOrder: (updateNewOrder: Partial<INewOrderRecord>) => {
      return dispatch(
        actions.newOrders.updateNewOrder(updateNewOrder),
      ) as unknown as Promise<void>;
    },
    commitOrder: () => {
      return dispatch(
        actions.assetBuilder.commitOrder(),
      ) as unknown as Promise<void>;
    },
    setAssetInstanceComparator: (assetInstances: AssetInstanceRecord) => {
      dispatch(actions.assetBuilder.setAssetInstanceComparator(assetInstances));
    },

    assetInstanceCounter: () => {
      dispatch(actions.assetBuilder.assetInstanceCounter());
    },

    resetAssetInstanceCounter: () => {
      dispatch(actions.assetBuilder.resetAssetInstanceCounter());
    },
    getWebIntegrations: (domain: string) => {
      dispatch(actions.assetBuilder.getWebIntegrations(domain));
    },
    createWebIntegration: (webIntegrationData: ILauncherData) => {
      dispatch(actions.assetBuilder.createWebIntegration(webIntegrationData));
    },
    deleteWebIntegration: (webIntegrationData: ILauncherData) => {
      dispatch(actions.assetBuilder.deleteWebIntegration(webIntegrationData));
    },
    generateImagesForLauncherPage: (
      images: string[],
      templateAndDimensionsArr: string[],
    ) => {
      dispatch(
        actions.assetBuilder.generateImagesForLauncherPage(
          images,
          templateAndDimensionsArr,
        ),
      );
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AssetLauncher);
