import { useMemo } from "react";
import { Drawer } from "antd";
import { Steps } from "./Steps";
import { Footer } from "./Footer";
import styles from "./ExportDrawer.module.scss";
import { ExportDestination } from "./types";
import { MatchColumns } from "./MatchColumns";
import { FillAdInfo } from "./FillAdInfo";
import { ExportSettings } from "./ExportSettings";
import { useAdLibraryExportContext } from "./AdLibraryExportContext";
import { useState } from "react";
import { MapData } from "./MapData";

interface ExportDrawerProps {
  onClose: () => void;
  open?: boolean;
}

export const ExportDrawer = ({ onClose, open }: ExportDrawerProps) => {
  const [currentStep, setCurrentStep] = useState(0);

  const {
    setAdType,
    setColumnMatches,
    setAdInfoUpdates,
    exportDestination,
    setExportDestination,
  } = useAdLibraryExportContext();

  const handleClose = () => {
    setAdType(null);
    setCurrentStep(0);
    setExportDestination(null);
    setColumnMatches({});
    setAdInfoUpdates({});

    onClose();
  };

  const CurrentStepComponent = useMemo(() => {
    const steps = {
      [ExportDestination.CSVDownload]: [],
      [ExportDestination.AdLibrary]: [MatchColumns, FillAdInfo],
      [ExportDestination.GoogleSpreadsheet]: [MapData],
    };

    const exportDestinationSteps = exportDestination
      ? steps[exportDestination]
      : [];
    return [ExportSettings, ...exportDestinationSteps][currentStep];
  }, [currentStep, exportDestination]);

  return (
    <Drawer
      visible={open}
      title={<Steps currentStep={currentStep} />}
      width="calc(100vw - 48px)"
      footer={
        <Footer
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          onClose={handleClose}
        />
      }
      className={styles.drawer}
      onClose={handleClose}
      closable={false}
      destroyOnClose
    >
      <CurrentStepComponent />
    </Drawer>
  );
};
