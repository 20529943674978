import {
  AccountField,
  AgentField,
  ImageField,
  ManualField,
  MarketingMaterial,
  OptionsField,
  ProductField,
} from "shared/types/marketingMaterials";
import {
  AgentGroupLayer,
  ConditionalGroupLayer,
  DisclosureGroupLayer,
  GroupLayer,
  Layer,
  OptionGroupLayer,
  OtherGroupLayer,
  Product,
  ProductGroupLayer,
  SPVariables,
  type Account,
  ManualGroupLayer,
} from "shared/types/salesEnablement";
import { ImageLayer, TextLayer } from "shared/types/salesEnablement/Layer";
import {
  isAccountGroupLayer,
  isAgentGroupLayer,
  isConditionalGroupLayer,
  isDisclosureGroupLayer,
  isManualGroupLayer,
  isOptionGroupLayer,
  isOtherGrouplayer,
  isProductGroupLayer,
  isTextLayer,
} from "../templates/fileDrawer/utils";
import { queryClient } from "queryClient";
import { QUERY_KEYS } from "screens/admin/products/utils/constants";
import { raise } from "utils/errorMessage";
import { useCallback } from "react";
import { dedupe, isTruthy } from "utils/helpers.array";
import { useFetchAccounts } from "shared/hooks/salesEnablement/useFetchAccounts";
import { useProducts } from "shared/hooks/admin/useProducts";
import { getOptionGroupChildOptions } from "utils/helpers.salesEnablement";
import { findMatchingCondition } from "utils/helpers.salesEnablement";
import {
  specialStateLicensePrefix,
  isSpecialState,
  isLicenseField,
} from "screens/adLibrary/marketingMaterialDrawer/hooks/agentManagement";

export type BuildLayersMetadata = {
  products: Product[];
  productTypes: string[];
  accounts?: Account[];
};

export type LayerDataValue =
  | string
  | string[]
  | Record<string, string | undefined>[]
  | undefined;
export type LayerData = {
  data: Record<string, LayerDataValue>;
  variables?: SPVariables;
};

export type GetLayersWithDataReturn = ({
  layers,
  material,
}: {
  layers: Layer[];
  material?: Partial<MarketingMaterial> | undefined;
}) => Record<string, LayerDataValue>;

export function useLayersWithData(settings?: BuildLayersSettings) {
  const { data: accounts } = useFetchAccounts();
  const { products, productTypes } = useProducts();
  const getLayersWithData: GetLayersWithDataReturn = useCallback(
    ({
      layers,
      material,
    }: {
      layers: Layer[];
      material?: Partial<MarketingMaterial>;
    }) =>
      buildLayers({
        layers,
        material,
        metadata: {
          products,
          productTypes,
          accounts: accounts ?? [],
        },
        settings,
      }),
    [accounts, productTypes, products, settings],
  );

  return {
    getLayersWithData,
  };
}

type BuildLayersSettings = {
  getName?: (layer: Layer) => string;
  getNestedLayersName?: (
    layer: Layer,
    subLayer?: OtherGroupLayer["subLayers"][number],
  ) => string;
};

type BuildLayersArgs = {
  layers: Layer[];
  settings?: BuildLayersSettings;
  material?: Partial<MarketingMaterial>;
  metadata: BuildLayersMetadata;
};

function buildLayers({
  layers,
  settings,
  material,
  metadata,
}: BuildLayersArgs): LayerData["data"] {
  return layers?.reduce<LayerData["data"]>((acc, pageLayer) => {
    const { variable: layerVariable, name: layerName } = pageLayer;

    const layerValue = getLayerValue({
      layer: pageLayer,
      material,
      metadata,
      settings,
    });
    const layerDataName =
      settings?.getName?.(pageLayer) ??
      trimAllSpaces(layerVariable?.name ?? layerName ?? "");
    return {
      ...acc,
      [layerDataName]: layerValue,
    };
  }, {});
}

type GetLayerValueArgs = Omit<BuildLayersArgs, "layers"> & { layer: Layer };
function getLayerValue({
  layer,
  material,
  metadata,
  settings,
}: GetLayerValueArgs): LayerDataValue {
  if (
    !material?.fields?.[layer.id] &&
    !(
      isConditionalGroupLayer(layer) ||
      isDisclosureGroupLayer(layer) ||
      isTextLayer(layer) ||
      isOptionGroupLayer(layer)
    )
  ) {
    if (
      isProductGroupLayer(layer) ||
      isAgentGroupLayer(layer) ||
      isManualGroupLayer(layer)
    ) {
      const { name } = layer;
      if (name === "OptionalLogo") return ""; // The layer name "OptionalLogo" is hard coded in the api side at the time of writing this. Refer to "OptionalLogoSnippetElementName" in "salesenablement/src/libs/utils.idml.ts"

      return [];
    }
    return layer?.variable?.raw ?? "";
  }

  switch (layer.type) {
    case "text":
      return getTextLayerValue(layer, material);
    case "image":
      return getImageLayerValue(layer, material);
    case "group":
      return getGroupLayerValue({
        layer,
        material,
        metadata,
        settings,
      });
    default: {
      const _never: never = layer;
      return _never;
    }
  }
}

function getTextLayerValue(
  layer: TextLayer,
  material?: Partial<MarketingMaterial>,
) {
  const fieldValue = material?.fields?.[layer.id];

  if (isLicenseField(layer.name)) {
    const specialStates = material?.locations?.filter(isSpecialState);
    if (!fieldValue) {
      if (specialStates?.length)
        return specialStates
          .map(stateId => `{{${stateId} License No.}}`)
          .join(" ");
      return "";
    }
  }

  if (!fieldValue) return layer.variable?.raw ?? "";

  if (typeof fieldValue !== "string")
    throw new Error("Field value should be a string");

  return fieldValue?.trim() ?? "";
}

function getImageLayerValue(
  layer: ImageLayer,
  material?: Partial<MarketingMaterial>,
) {
  return imageNameWithExtension(material?.fields?.[layer.id] as ImageField);
}

type GetGroupLayerValueArgs = GetLayerValueArgs & { layer: GroupLayer };
export function getGroupLayerValue({
  layer,
  material,
  metadata,
  settings,
}: GetGroupLayerValueArgs): LayerDataValue {
  if (!material) {
    if (isAgentGroupLayer(layer) || isAccountGroupLayer(layer)) {
      return [];
    }
    return layer.variable?.raw ?? "";
  }

  if (
    layer.smartVariable === "expirationDate" ||
    layer.smartVariable === "formNumber"
  ) {
    return (material.fields?.[layer.id] as string) ?? layer.variable?.raw ?? "";
  }
  if (isOptionGroupLayer(layer)) {
    return buildOptionGroupLayer(layer, material);
  }

  if (isAgentGroupLayer(layer)) {
    return buildAgentGroupLayer(layer, material, settings);
  }

  if (isProductGroupLayer(layer)) {
    return buildProductGroupLayer(layer, material);
  }
  if (isDisclosureGroupLayer(layer)) {
    return buildDisclosureGroupLayer(layer, material);
  }
  if (isConditionalGroupLayer(layer)) {
    return buildConditionalGroupLayer(layer, material, metadata);
  }
  if (isAccountGroupLayer(layer)) {
    return buildAccountGroupLayer(layer, material, metadata.accounts);
  }

  if (isManualGroupLayer(layer)) {
    return buildManualGroupLayer(layer, material, metadata);
  }

  if (isOtherGrouplayer(layer)) {
    return buildOtherGroupLayer(layer);
  }

  const _never: never = layer;
  return _never;
}

function buildAccountGroupLayer(
  layer: GroupLayer,
  material: Partial<MarketingMaterial>,
  accounts?: Account[],
) {
  if (!accounts) return [];

  // Account field was once a string, but now it is an object
  // this support old templates
  const accountId =
    typeof material?.fields?.[layer.id] === "string"
      ? material?.fields?.[layer.id]
      : (material?.fields?.[layer.id] as AccountField)?.value;
  return accounts.find(account => account.id === accountId)?.name ?? "";
}

function buildConditionalGroupLayer(
  pageLayer?: ConditionalGroupLayer,
  material?: Partial<MarketingMaterial>,
  metadata?: BuildLayersMetadata,
) {
  if (!material?.language || !pageLayer?.conditionalSet) return "";

  const productFields: ProductField[] = Object.values(
    material.fields ?? {},
  ).filter(
    (field): field is ProductField =>
      typeof field == "object" && field.type === "product",
  );

  const productIds =
    productFields
      .flatMap(field => field.productsData.map(product => product.productId))
      .filter(isTruthy) || [];
  const productFieldTypes: string[] = productIds
    .map(
      id => metadata?.products?.find(product => product.id === id)?.productType,
    )
    .filter(dedupe)
    .filter(isTruthy);
  const productTypes = material?.productTypeOffer ?? productFieldTypes;
  const matches = findMatchingCondition(
    pageLayer.conditionalSet.fields,
    pageLayer.conditionalSet.runAllConditions,
    material.locations ?? [],
    productIds,
    productTypes,
  );

  return matches.map(field => field.then?.[material.language!]).join(`\n\r`);
}

function buildOtherGroupLayer(
  pageLayer: OtherGroupLayer,
  settings?: BuildLayersSettings,
): LayerDataValue {
  const { subLayers } = pageLayer;

  return [
    subLayers.reduce<Record<string, string>>((acc, sublayer) => {
      const { variable: subLayerVariable } = sublayer;
      if (!subLayerVariable) return acc;
      const { name } = subLayerVariable;
      const value = subLayerVariable.raw ?? "";
      return {
        ...acc,
        [settings?.getNestedLayersName?.(pageLayer, sublayer) ??
        trimAllSpaces(name)]: value,
      };
    }, {}),
  ];
}

function buildAgentGroupLayer(
  pageLayer: AgentGroupLayer,
  material: Partial<MarketingMaterial>,
  settings?: BuildLayersSettings,
): LayerDataValue {
  const field = material?.fields?.[pageLayer.id] as AgentField;
  return field.agentsData.map(agentData => {
    const specialStates = material.locations?.filter(isSpecialState);

    const agentEntries = Object.entries(agentData).filter(
      ([key]) => key && key !== "refAgentId",
    );
    const licenseFields = agentEntries
      .filter(([key]) => isLicenseField(key))
      .map(([key]) => key);

    return agentEntries.reduce<Record<string, string | undefined>>(
      (acc, [key, value]) => {
        const subLayer = pageLayer.subLayers.find(({ name }) => name === key);

        const finalKey =
          settings?.getNestedLayersName?.(pageLayer, subLayer) ??
          trimAllSpaces(key);

        const trimmedValue = value?.trim().length ? value.trim() : undefined;

        acc[finalKey] = getFinalValue(trimmedValue);
        return acc;

        function getFinalValue(prevValue: string | undefined) {
          const licenseIndex = licenseFields.findIndex(
            licenseField => licenseField === key,
          );
          const foundSpecialState = specialStates?.[licenseIndex];
          if (foundSpecialState) {
            return prevValue
              ? specialStateLicensePrefix(foundSpecialState) + prevValue
              : `{{${foundSpecialState} License No.}}`;
          }
          if (licenseIndex >= 0) {
            return prevValue ?? "";
          }

          return prevValue;
        }
      },
      {},
    );
  });
}

function buildOptionGroupLayer(
  layer: OptionGroupLayer,
  material?: Partial<MarketingMaterial>,
) {
  const field = material?.fields?.[layer.id] as OptionsField;
  if (!field.optionId) return layer.variable?.raw ?? "";

  const option = safeFind(
    layer.options,
    opt => opt.id === field.optionId,
  ).value;
  const hasOptions = !!field.childOptions;

  if (hasOptions) {
    const layerVariables = getOptionGroupChildOptions(option);

    return layerVariables.reduce((resultStr, variable, index) => {
      const accessor = `${variable}-${index}`;
      const specialStates = material?.locations?.filter(isSpecialState);

      const emptyLicenseValue = specialStates?.length
        ? specialStates.map(stateId => `{{${stateId} License No.}}`).join(" ")
        : "";
      const emptyValue = isLicenseField(variable)
        ? emptyLicenseValue
        : `{{${variable}}}`;
      return field.childOptions[accessor]
        ? resultStr.replace(/{{.*?}}/, field.childOptions[accessor])
        : emptyValue;
    }, option);
  } else {
    return option;
  }
}

function buildDisclosureGroupLayer(
  pageLayer: DisclosureGroupLayer,
  material: Partial<MarketingMaterial>,
): LayerDataValue {
  const field = material?.fields?.[pageLayer.id] as string;
  if (field) return field;
  const { subLayers } = pageLayer;
  if (!subLayers?.length) return "";
  const { variable: subLayerVariable } = subLayers[0];
  if (!subLayerVariable) return "";
  return subLayerVariable.raw ?? "";
}

function buildProductGroupLayer(
  layer: ProductGroupLayer,
  material?: Partial<MarketingMaterial>,
) {
  const field = material?.fields?.[layer.id] as ProductField;
  const productsData = queryClient.getQueryData<
    { result: Product[] } | undefined
  >(QUERY_KEYS.fetchProducts);
  if (!productsData?.result) return undefined;

  return field.productsData
    .map(productData => {
      const product = productsData.result.find(
        prod => prod.id == productData.productId,
      )?.details?.[material?.language || "en"];

      if (!product) return undefined;

      return layer.subLayers.reduce(
        (accum, subLayer) => {
          if (subLayer.name.toLowerCase() === "product")
            accum["Product"] = product.name ?? "";
          if (subLayer.name.toLowerCase() === "product description")
            accum["Productdescription"] = product.description ?? "";
          return accum;
        },
        { Product: "", ["Productdescription"]: "" },
      );
    })
    .filter(
      (p): p is { Product: string; ["Productdescription"]: string } => !!p,
    );
}

function buildManualGroupLayer(
  layer: ManualGroupLayer,
  material: Partial<MarketingMaterial>,
  metadata: BuildLayersMetadata,
) {
  const field = material?.fields?.[layer.id] as ManualField;

  if (!field?.fieldsData) return [];

  return field.fieldsData?.map?.(({ field }) => {
    return layer.subLayers.reduce((accum, subLayer) => {
      const key = trimAllSpaces(subLayer.name);
      switch (subLayer.type) {
        case "group":
          return {
            ...accum,
            [key]: buildConditionalGroupLayer(
              subLayer as ConditionalGroupLayer,
              material,
              metadata,
            ),
          };
        case "text":
          return {
            ...accum,
            [key]: field[subLayer.id] ?? subLayer.variable?.raw ?? "",
          };
        case "image":
          return {
            ...accum,
            [key]: imageNameWithExtension(field[subLayer.id] as ImageField),
          };
      }
    }, {});
  });
}
const trimAllSpaces = (str: string) => str?.replace(/\s/g, "");

const safeFind = <T>(arr: T[], predicate: (elem: T) => boolean): T => {
  return arr.find(predicate) ?? raise("Element not found");
};

const imageNameWithExtension = (image?: ImageField) =>
  image?.url?.split("/")?.pop() ?? "";
