import { CallToAction } from "shared/types/assetExporter";
import { FillAdInfoTableData, RecommendedColumnTitle } from "../types";

export const isCallToActionValue = (value: string) => {
  return Object.values(CallToAction).some(
    cta =>
      cta.replace(/\s+/g, "_").toLowerCase() ===
      value.trim().replace(/\s+/g, "_").toLowerCase(),
  );
};

export const validateCTAButton = (
  rows: FillAdInfoTableData[],
  ctaValidity: Record<string, boolean>,
  setCtaValidity: React.Dispatch<
    React.SetStateAction<{
      [key: string]: boolean;
    }>
  >,
) => {
  rows.forEach((rowData, index) => {
    const dataValue = rowData[RecommendedColumnTitle.CTAButton]?.toString();
    if (!dataValue) return;
    const isValidCTA = isCallToActionValue(dataValue);

    if (ctaValidity[index] !== isValidCTA) {
      setCtaValidity(prevState => ({
        ...prevState,
        [index]: isValidCTA,
      }));
    }
  });
};

export const hasInvalidCta = (ctaValidity: Record<string, boolean>) => {
  return Object.values(ctaValidity).some(isValid => !isValid);
};
