import CommonFilterDrawer from "shared/components/filterDrawer/FilterDrawer";
import {
  FilterFields,
  SelectFilterOption,
} from "shared/components/filterDrawer/useFilterFields";
import { MarketingMaterialTableItem } from "shared/types/marketingMaterials";
import { useFetchMarketingMaterials } from "./hooks/useFetchMarketingMaterials";
import { states } from "shared/constants/states";
import {
  audienceFilterOptions,
  Languages,
} from "utils/helpers.salesEnablement";
import { deliveryMethods } from "screens/designStudio/utils";
import { uniq } from "lodash";
import { useMemo } from "react";
import { isTruthy } from "utils/helpers.array";
import { customizableOptions } from "screens/designStudio/templates/fileDrawer/FormFields.schema";

type Props = {
  onClose: () => void;
  open: boolean;
};

const MarketingMaterialFilterDrawer = ({ open, onClose }: Props) => {
  const { marketingMaterials } = useFetchMarketingMaterials();

  const productOptions = useMemo(
    () =>
      getUniqueOptionsFromMaterials(
        marketingMaterials,
        material => material.products,
      ),
    [marketingMaterials],
  );

  const accountOptions = useMemo(
    () =>
      getUniqueOptionsFromMaterials(
        marketingMaterials,
        material => material.account,
      ),
    [marketingMaterials],
  );

  const filterFields: FilterFields<MarketingMaterialTableItem> = [
    {
      title: "Name",
      key: "name",
      type: "text",
    },
    {
      title: "Delivery Method",
      key: "templateDeliveryMethods",
      type: "select",
      options: deliveryMethods,
    },
    {
      title: "Allowed States",
      key: "locations",
      type: "select",
      options: Object.values(states).map(state => {
        return {
          label: `${state.id} - ${state.name}`,
          value: state.id,
          key: state.id,
        };
      }),
    },
    {
      title: "Template Customizable",
      key: "templateCustomizable",
      type: "select",
      options: customizableOptions,
    },
    {
      title: "Account",
      key: "account",
      type: "select",
      options: accountOptions,
    },
    {
      title: "Audience",
      key: "templateAudience",
      type: "select",
      options: audienceFilterOptions,
    },
    {
      title: "Product Specific",
      key: "products",
      type: "select",
      options: productOptions,
    },
    {
      title: "Language",
      key: "language",
      type: "select",
      options: Object.values(Languages).map(lang => {
        return {
          label: lang.label,
          value: lang.value,
          key: lang.value,
        };
      }),
    },
  ];

  return (
    <CommonFilterDrawer
      data={marketingMaterials}
      filterKey="marketing-materials"
      fields={filterFields}
      open={open}
      onClose={onClose}
    />
  );
};

function getUniqueOptionsFromMaterials(
  marketingMaterials: MarketingMaterialTableItem[],
  accessor: (
    material: MarketingMaterialTableItem,
  ) => string | string[] | undefined,
): SelectFilterOption[] {
  return uniq(
    marketingMaterials
      .flatMap(material => {
        const value = accessor(material);
        return Array.isArray(value) ? value : [value];
      })
      .filter(isTruthy),
  ).map<SelectFilterOption>(item => ({
    value: item,
    key: item,
    label: item,
  }));
}

export default MarketingMaterialFilterDrawer;
